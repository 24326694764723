import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { FaChevronDown } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { onShowModalHandler, toggleLeftMenuSidebarShow } from '../../redux/modalSlice'
import {
  selectSideSportMenu,
  setOverseasParamsByPopularFixtureId,
  setOverseasParamsByPopularLeagueId,
} from '../../redux/sportsOverseasInfoSlice'
import isLogin from '../../utils/enums/authority'
import CustomAlert from '../CustomAlert'
import AuthorityAlertPopup from '../Popup/Error/AuthorityAlertPopup'
import { HermesMainText } from '../../utils/palette'
import CustomCollapse2 from './CustomCollapse2'
import { convertToKstDateTimeSimple } from '../../utils/dateTime'

const SportsGameSidebar = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { overseasCategory, overseasPopularLeagueCategory, overseasPopularFixtureCategory } = useSelector(state => {
    const { sportsOverseasInfo } = state

    return {
      overseasCategory: sportsOverseasInfo.overseasCategory,
      overseasPopularLeagueCategory: sportsOverseasInfo.overseasPopularLeagueCategory,
      overseasPopularFixtureCategory: sportsOverseasInfo.overseasPopularFixtureCategory,
    }
  })

  const { noteRedisSize, oneToOneRedisSize, attendanceUse } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      attendanceUse: memberInfo.attendanceUse,
    }
  })

  const { isShowLeftMenuSidebar } = useSelector(state => {
    const { modalInfo } = state

    return {
      isShowLeftMenuSidebar: modalInfo.isShowLeftMenuSidebar,
    }
  })

  const [isSmallSize, setIsSmallSize] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      const resultMatches = window.matchMedia('(max-width: 1670px)').matches

      setIsSmallSize(resultMatches)
      if (resultMatches) {
        dispatch(toggleLeftMenuSidebarShow(false))
      } else {
        dispatch(toggleLeftMenuSidebarShow(true))
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [isOpenedQuickLinks, setIsOpenedQuickLinks] = useState(true)
  const [isOpenedQuickLinks2, setIsOpenedQuickLinks2] = useState(true)
  const [isOpenedQuickLinks3, setIsOpenedQuickLinks3] = useState(true)
  const [isOpenedQuickLinks4, setIsOpenedQuickLinks4] = useState(true)

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMenuModalHandler = title => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  const [selectedSportId, setSelectedSportId] = useState(-1)
  const [selectedLocationId, setSelectedLocationId] = useState(-1)

  // 종목 선택했을때
  const onClickSportHandler = sportId => {
    if (selectedSportId === -1 || selectedSportId !== sportId) {
      setSelectedSportId(sportId)
    } else {
      if (!isLogin()) {
        setAuthorityAlertShow(true)
        return
      }

      if (noteRedisSize > 0) {
        setNoteAlertShow(true)
        return
      }

      // 초기화
      setSelectedSportId(-1)

      dispatch(
        selectSideSportMenu({
          sportId,
          locationId: -1,
          leagueId: -1,
        }),
      )

      // TODO 국내 해외 스페셜 라이브 구분하기
      if (location.pathname !== '/sports/overseas') {
        navigate('/sports/overseas')
      }
    }
  }

  // 국가 선택시
  const onClickLocationHandler = (sportId, locationId) => {
    if (selectedLocationId === -1 || selectedLocationId !== locationId) {
      setSelectedSportId(sportId)
      setSelectedLocationId(locationId)
    } else {
      if (!isLogin()) {
        setAuthorityAlertShow(true)
        return
      }

      if (noteRedisSize > 0) {
        setNoteAlertShow(true)
        return
      }

      // 초기화
      setSelectedSportId(-1)
      setSelectedLocationId(-1)

      dispatch(
        selectSideSportMenu({
          sportId,
          locationId,
          leagueId: -1,
        }),
      )

      // TODO 국내 해외 스페셜 라이브 구분하기
      if (location.pathname !== '/sports/overseas') {
        navigate('/sports/overseas')
      }
    }
  }

  // 스포츠 리그 선택시
  const onClickLeagueHandler = (sportId, locationId, leagueId) => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    // 초기화
    setSelectedSportId(-1)
    setSelectedLocationId(-1)

    dispatch(
      selectSideSportMenu({
        sportId,
        locationId,
        leagueId,
      }),
    )

    // TODO 국내 해외 스페셜 라이브 구분하기
    if (location.pathname !== '/sports/overseas') {
      navigate('/sports/overseas')
    }
  }

  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    navigate(link)
  }

  // 인기게임 선택했을때
  const onClickPopularFixtureHandler = (sportId, fixtureId) => {
    // if (!sportId  || selectedSportId !== sportId) {
    //   setSelectedSportId(sportId)
    // } else {

    // }
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    dispatch(
      setOverseasParamsByPopularFixtureId({
        sportId,
        fixtureId,
      }),
    )

    // TODO 국내 해외 스페셜 라이브 구분하기
    if (location.pathname !== '/sports/overseas') {
      navigate('/sports/overseas')
    }
  }

  // 인기게임 선택했을때
  const onClickPopularLeagueHandler = (sportId, leagueId) => {
    // if (!sportId  || selectedSportId !== sportId) {
    //   setSelectedSportId(sportId)
    // } else {

    // }
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    dispatch(
      setOverseasParamsByPopularLeagueId({
        sportId,
        leagueId,
      }),
    )

    // TODO 국내 해외 스페셜 라이브 구분하기
    if (location.pathname !== '/sports/overseas') {
      navigate('/sports/overseas')
    }
  }

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <AuthorityAlertPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      <SidebarWrap className={`${isSmallSize ? 'smallSize' : ''} ${isShowLeftMenuSidebar ? 'menuOpen' : ''}`}>
        <SlimScrollDiv>
          <SidbarMenuWrap className="sidebar-container">
            <QuickLinksWrap>
              <QuickLinksCollapse
                onClick={() => {
                  setIsOpenedQuickLinks(prev => !prev)
                }}
              >
                <QuickLinksText>Quick Links</QuickLinksText>
                <CustomFaChevronDown />
              </QuickLinksCollapse>

              <CustomCollapse in={isOpenedQuickLinks}>
                <QuickLinksBox>
                  <SidbarQuickItem
                    onClick={() => {
                      onClickMenuModalHandler('BETTING_HISTORY')
                    }}
                  >
                    <SidbarQuickItemIconBox>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-betting-history.png`} alt="" />
                    </SidbarQuickItemIconBox>

                    <SidbarQuickItemText>배팅내역</SidbarQuickItemText>
                  </SidbarQuickItem>
                  <SidbarQuickItem
                    onClick={() => {
                      onClickMenuModalHandler('MONEY_EXCHANGE')
                    }}
                  >
                    <SidbarQuickItemIconBox>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-money-exchange.png`} alt="" />
                    </SidbarQuickItemIconBox>

                    <SidbarQuickItemText>머니이동내역</SidbarQuickItemText>
                  </SidbarQuickItem>
                  <SidbarQuickItem
                    onClick={() => {
                      onClickMenuModalHandler('COUPON')
                    }}
                  >
                    <SidbarQuickItemIconBox>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-coupon.png`} alt="" />
                    </SidbarQuickItemIconBox>

                    <SidbarQuickItemText>쿠폰사용</SidbarQuickItemText>
                  </SidbarQuickItem>
                  <SidbarQuickItem
                    onClick={() => {
                      onClickMenuModalHandler('POINT_EXCHANGE')
                    }}
                  >
                    <SidbarQuickItemIconBox>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-exchange.png`} alt="" />
                    </SidbarQuickItemIconBox>

                    <SidbarQuickItemText>포인트 전환</SidbarQuickItemText>
                  </SidbarQuickItem>
                  <SidbarQuickItem
                    onClick={() => {
                      onClickMenuModalHandler('FRIEND')
                    }}
                  >
                    <SidbarQuickItemIconBox>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-friend.png`} alt="" />
                    </SidbarQuickItemIconBox>

                    <SidbarQuickItemText>지인추천</SidbarQuickItemText>
                  </SidbarQuickItem>
                  {attendanceUse && (
                    <SidbarQuickItem
                      onClick={() => {
                        onClickMenuModalHandler('ATTENDANCE')
                      }}
                    >
                      <SidbarQuickItemIconBox>
                        <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-attendance.png`} alt="" />
                      </SidbarQuickItemIconBox>

                      <SidbarQuickItemText>출석현황</SidbarQuickItemText>
                    </SidbarQuickItem>
                  )}
                  <SidbarQuickItem
                    onClick={e => {
                      onClickMenuHandler('/customcenter/event')
                    }}
                  >
                    <SidbarQuickItemIconBox>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-event.png`} alt="" />
                    </SidbarQuickItemIconBox>

                    <SidbarQuickItemText>이벤트</SidbarQuickItemText>
                  </SidbarQuickItem>
                  <SidbarQuickItem
                    onClick={() => {
                      onClickMenuModalHandler('NOTICE_EVENT')
                    }}
                    style={{ marginBottom: '30px' }}
                  >
                    <SidbarQuickItemIconBox>
                      <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-notice.png`} alt="" />
                    </SidbarQuickItemIconBox>

                    <SidbarQuickItemText>공지/규정</SidbarQuickItemText>
                  </SidbarQuickItem>
                </QuickLinksBox>
              </CustomCollapse>
            </QuickLinksWrap>
            <PopularWrap isSmallSize={isSmallSize} menuOpen={isShowLeftMenuSidebar}>
              <PopularTitle
                onClick={() => {
                  setIsOpenedQuickLinks3(prev => !prev)
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/sidebar/cup.png`} alt="" />
                최신 인기게임
                <img src={`${process.env.PUBLIC_URL}/img/sidebar/down.png`} alt="" />
              </PopularTitle>
              <CustomCollapse2 in={isOpenedQuickLinks3}>
                {overseasPopularFixtureCategory.length === 0 ? (
                  <PopularTeamList>
                    <CustomTeamDiv style={{ paddingLeft: '10px' }}>최신 인기경기가 없습니다.</CustomTeamDiv>
                  </PopularTeamList>
                ) : (
                  overseasPopularFixtureCategory.map(sport => (
                    <PopularTeamList
                      key={sport.sportId}
                      onClick={() => {
                        onClickPopularFixtureHandler(sport.sportId, sport.fixtureId)
                      }}
                    >
                      <span style={{ color: `${HermesMainText}` }}>{convertToKstDateTimeSimple(sport.startAt)}</span>
                      <img
                        style={{ width: '23px', height: '23px' }}
                        src={`${process.env.PUBLIC_URL}/img/sidebar/side-${sport.sportId}.png`}
                        alt=""
                      />
                      <CustomTeamDiv>
                        <span>
                          {sport.homeImageUrl && <img src={`${sport.homeImageUrl}`} alt="" />}

                          {sport.homeName}
                        </span>
                        <span>
                          {sport.awayImageUrl && <img src={`${sport.awayImageUrl}`} alt="" />}
                          {sport.awayName}
                        </span>
                      </CustomTeamDiv>
                    </PopularTeamList>
                  ))
                )}
              </CustomCollapse2>
            </PopularWrap>
            <PopularWrap isSmallSize={isSmallSize} menuOpen={isShowLeftMenuSidebar}>
              <PopularTitle
                onClick={() => {
                  setIsOpenedQuickLinks4(prev => !prev)
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/sidebar/top.png`} alt="" />
                최신 인기리그
                <img src={`${process.env.PUBLIC_URL}/img/sidebar/down.png`} alt="" />
              </PopularTitle>
              <CustomCollapse2 in={isOpenedQuickLinks4}>
                {overseasPopularLeagueCategory.length === 0 ? (
                  <PopularLeagueList>
                    <CustomLeagueDiv>최신 인기리그가 없습니다.</CustomLeagueDiv>
                  </PopularLeagueList>
                ) : (
                  overseasPopularLeagueCategory.map(sport => (
                    <PopularLeagueList
                      key={sport.sportId}
                      onClick={() => {
                        onClickPopularLeagueHandler(sport.sportId, sport.leagueId)
                      }}
                    >
                      <img
                        style={{ width: '23px', height: '23px' }}
                        src={`${process.env.PUBLIC_URL}/img/sidebar/side-${sport.sportId}.png`}
                        alt=""
                      />
                      <CustomLeagueDiv>
                        {sport.leagueImageUrl && <img src={`${sport.leagueImageUrl}`} alt="" />}
                        {sport.leagueName}
                      </CustomLeagueDiv>
                    </PopularLeagueList>
                  ))
                )}
              </CustomCollapse2>
            </PopularWrap>

            <QuickLinksWrap2>
              <QuickLinksCollapse
                onClick={() => {
                  setIsOpenedQuickLinks2(prev => !prev)
                }}
              >
                <QuickLinksText>스포츠</QuickLinksText>
                <CustomFaChevronDown />
              </QuickLinksCollapse>
              <CustomCollapse in={isOpenedQuickLinks2}>
                <SportsMenuBodyWrap>
                  <SportsMenuContentBox>
                    {overseasCategory.map(sport => {
                      if (sport.sportId === -1) return

                      return (
                        <>
                          <SportsMenuItemWrap
                            onClick={() => {
                              onClickSportHandler(sport.sportId)
                            }}
                          >
                            <SportsMenuItemBox>
                              <div
                                style={{
                                  whiteSpace: 'nowrap',
                                  position: 'relative',
                                  display: 'block',
                                  width: '100%',
                                }}
                              >
                                <SportsMenuItemCount>{sport.sportCount}</SportsMenuItemCount>
                                <SportsMenuItemIconBox>
                                  <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-${sport.sportId}.png`} alt="" />
                                </SportsMenuItemIconBox>
                                <SportsMenuItemText>{sport.sportName}</SportsMenuItemText>
                              </div>
                            </SportsMenuItemBox>
                          </SportsMenuItemWrap>
                          {sport.locations.length > 0 && (
                            <SportCustomCollapse in={sport.sportId === selectedSportId}>
                              <div>
                                {sport.locations.map(locationData => {
                                  return (
                                    <>
                                      <SportsMenuItemWrap
                                        onClick={() => {
                                          onClickLocationHandler(sport.sportId, locationData.locationId)
                                        }}
                                      >
                                        <SportsMenuItemBox active={locationData.locationId === selectedLocationId}>
                                          <div
                                            style={{
                                              whiteSpace: 'nowrap',
                                              position: 'relative',
                                              display: 'block',
                                              width: '100%',
                                            }}
                                          >
                                            <SportsMenuItemCount>{locationData.locationCount}</SportsMenuItemCount>
                                            <SportsMenuItemIconBox>
                                              <img src={locationData.locationImageUrl} alt="" />
                                            </SportsMenuItemIconBox>
                                            <SportsMenuItemText>{locationData.locationName}</SportsMenuItemText>
                                          </div>
                                        </SportsMenuItemBox>
                                      </SportsMenuItemWrap>
                                      {locationData.leagues.length > 0 && (
                                        <SportCustomCollapse in={locationData.locationId === selectedLocationId}>
                                          <div>
                                            {locationData.leagues.map(league => {
                                              return (
                                                <SportsMenuItemWrap
                                                  onClick={() => {
                                                    onClickLeagueHandler(
                                                      sport.sportId,
                                                      locationData.locationId,
                                                      league.leagueId,
                                                    )
                                                  }}
                                                >
                                                  <SportsMenuItemBox isLeague>
                                                    <div
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        position: 'relative',
                                                        display: 'block',
                                                        width: '100%',
                                                      }}
                                                    >
                                                      <SportsMenuItemCount>{league.leagueCount}</SportsMenuItemCount>
                                                      <SportsMenuItemIconBox>
                                                        <img src={league.leagueImageUrl} alt="" />
                                                      </SportsMenuItemIconBox>
                                                      <SlimScrollDiv>
                                                        <SportsMenuItemText>{league.leagueName}</SportsMenuItemText>
                                                      </SlimScrollDiv>
                                                    </div>
                                                  </SportsMenuItemBox>
                                                </SportsMenuItemWrap>
                                              )
                                            })}
                                          </div>
                                        </SportCustomCollapse>
                                      )}
                                    </>
                                  )
                                })}
                              </div>
                            </SportCustomCollapse>
                          )}
                        </>
                      )
                    })}
                  </SportsMenuContentBox>
                </SportsMenuBodyWrap>
              </CustomCollapse>
            </QuickLinksWrap2>
          </SidbarMenuWrap>
        </SlimScrollDiv>
      </SidebarWrap>
    </>
  )
}

export default SportsGameSidebar

const CustomLeagueDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  img {
    width: 23px;
    height: 23px;
  }
`

const CustomTeamDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-left: -10px;
  span {
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    width: 100%;
    font-size: 12px;

    img {
      width: 18px;
      height: 18px;
    }
  }
`

const PopularTeamList = styled.div`
  border-bottom: 1px solid #262626;
  color: #fff;
  cursor: pointer;
  height: 44px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 20px;
  img {
    width: 23px;
    height: 23px;
  }
  &:hover {
    background: #1f1f1f;
  }
`

const PopularLeagueList = styled.div`
  border-bottom: 1px solid #262626;
  color: #fff;
  cursor: pointer;
  height: 44px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 30px;
  img {
    width: 23px;
    height: 23px;
  }
  &:hover {
    background: #1f1f1f;
  }
`

const PopularTitle = styled.div`
  border-top: 1px solid #565656;
  border-bottom: 3px solid #111;
  background-image: linear-gradient(180deg, #101010 0, #121212 20%, #181617 50%, #1c1a1b 80%, #201c1b) !important;
  color: #fff;
  font-weight: 900;
  font-size: 14px;
  position: relative;
  height: 46px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  img:first-child {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  img:last-child {
    margin-left: auto; /* 오른쪽으로 밀기 */
    margin-right: 10px;
    width: 20px;
    height: 9px;
  }
`

// collapse 부분
const QuickLinksCollapse = styled.div`
  height: 44px;
  padding: 12px 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: table;
  transition: background-color 0.1s ease-out 0s;
  white-space: nowrap;

  &:hover {
    background-color: #1f1f1f;
  }

  margin-top: 8px;
  font-weight: 900;
  cursor: pointer;
`

const SportsMenuHeaderBox = styled.div`
  padding: 12px 8px;
  white-space: nowrap;
  font-weight: 900;
  margin-top: 8px
  display: block;

`

const SportsMenuItemCount = styled.div`
  font-weight: 100;
  float: right;
  padding: 5px 0;
  margin-top: 1px;
  display: inline;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  width: 16.66667%;
`

const SportsMenuItemIconBox = styled.div`
  width: 12.5%;
  float: left;
  display: block;
  margin-top: 4px;

  img {
    width: 16px;
  }
`

const SportsMenuItemText = styled.div`
  padding: 2px 3px 3px 3px;
  width: 150px;
  float: left;
  font-size: 13px;
  display: block;
  word-break: break-all;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 1;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid transparent;
    background: #ccc;
  }
`

const SidbarQuickItemText = styled.div`
  float: left;
  padding-left: 24px;
  width: calc(100% - 16px);
  font-size: 14px;
`

const SidbarMenuWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    width: 0 !important; /* 스크롤 바 너비를 0으로 설정하여 숨김 */
  }
`

const SidebarWrap = styled.div`
  transition: all 0.5s ease-out 0s;
  width: 50px; // 기본적으로 닫힌 상태
  position: fixed;
  margin-top: 65px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1010;
  -webkit-transform: translateZ(0);
  font-family: 'Malgun Gothic', 'dotum';
  background-color: #141517;

  ${QuickLinksCollapse}, ${SportsMenuHeaderBox}, ${SportsMenuItemCount}, ${SportsMenuItemText}, ${SidbarQuickItemText} {
    display: none; // 기본적으로 숨김
  }

  ${SidbarMenuWrap} {
    &::-webkit-scrollbar {
      width: 0; /* 스크롤 바 너비를 0으로 설정하여 숨김 */
    }
  }

  &.menuOpen,
  &.smallSize:hover,
  &:not(.smallSize):not(.menuOpen):hover {
    width: 271px;

    ${QuickLinksCollapse} {
      display: table;
    }
    ${SportsMenuHeaderBox}, ${SportsMenuItemCount}, ${SportsMenuItemText}, ${SidbarQuickItemText} {
      display: block;
    }
    ${SidbarMenuWrap} {
      &::-webkit-scrollbar {
        width: 6px; /* 스크롤 바 너비를 0으로 설정하여 숨김 */
      }
    }
  }
`

// 스크롤 용도
const SlimScrollDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
`

// text-white 부분
const QuickLinksWrap = styled.div`
  color: #fff;
  padding: 35px 8px 0 8px;
`

// text-white 부분
const QuickLinksWrap2 = styled.div`
  color: #fff;
  padding: 0px 8px 0 8px;
`

const QuickLinksText = styled.div`
  width: calc(100% - 24px);
  float: left;
  font-size: 14px;
`

const CustomFaChevronDown = styled(FaChevronDown)`
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-top: 3px;
  margin-right: 10px;
  float: right;
`

const CustomCollapse = styled(Collapse)`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
    // transition: height 3s ease-in-out !important;
  }
`

const SportCustomCollapse = styled(Collapse)`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
    // transition: height 3s ease-in-out !important;
  }
`

const QuickLinksBox = styled.div``

const SidbarQuickItem = styled.div`
  height: 44px;
  padding: 12px 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: table;
  transition: background-color 0.1s ease-out 0s;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: #232425;
  }
`

const SidbarQuickItemIconBox = styled.div`
  float: left;
  width: 16px;
  padding-left: 2px;
  diplay: block;
  cursor: pointer;
  white-space: nowrap;
  img {
    width: 100%;
  }
`

const SportsMenuBodyWrap = styled.div`
  border-radius: 0 0 3px 3px;
  background: #141517;
  padding: 0 8px;
  display: block;
  font-family: 'Malgun Gothic', dotum;
`

const SportsMenuContentBox = styled.div`
  display: block;
`

const SportsMenuItemWrap = styled.div`
  position: relative;
  display: block;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
`
const SportsMenuItemBox = styled.div`
  line-height: 20px;
  position: relative;
  display: flex;
  background: #141517;
  padding: 12px 2px;
  border: none;
  transition: background-color 0.1s ease-out 0s;
  color: #fff;
  cursor: pointer;
  height: 100%;

  &:hover {
    cursor: pointer;
    background-color: #232425;
  }

  ${props =>
    props.active &&
    `
    background-color: #232425;
`}

  ${props =>
    props.isLeague &&
    `
    padding-left: 17px;
    &:hover {
      background-color: #555759;
    }
`}
`

const SilverStyled = styled.div`
  border: none;

  // Colors - 위아래 순서 바꾸기!!
  background-image: linear-gradient(180deg, #525252 23.07%, #262626 91.02%),
    linear-gradient(180deg, #ffffff 0%, #424242 58.85%, #c1c1c1 63.02%, #dedede 83.85%, #313131 100%);

  background-origin: border-box;
  background-clip: content-box, border-box;

  // 마우스 호버
  &:hover {
    cursor: pointer;

    // css 나온대로 쓰고
    background: #000000;

    background: linear-gradient(0deg, #000000, #000000),
      linear-gradient(180deg, #ffffff 0%, #424242 58.85%, #c1c1c1 63.02%, #dedede 83.85%, #313131 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    cursor: pointer;

    background: #383838;

    background: linear-gradient(0deg, #383838, #383838),
      linear-gradient(180deg, #ffffff 0%, #424242 58.85%, #c1c1c1 63.02%, #dedede 83.85%, #313131 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 비활성화
  &:disabled {
    box-shadow: none;
    background: #242424;

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffffff4d;
  }
`

const GoldStyled = styled.div`
  border: none;
  // border: 1px solid #edae07;

  background: linear-gradient(180deg, #e2c98d 23.07%, #ba9954 99.82%);
  box-shadow: 0px 0px 5px 0px #745400 inset;

  background-image: linear-gradient(180deg, #e2c98d 23.07%, #ba9954 99.82%),
    linear-gradient(180deg, #fff7e4 0%, #fde2ab 53.12%, #c89030 67.19%, #ffe9b9 92.71%, #4a2111 100%);

  background-origin: border-box;
  background-clip: content-box, border-box;

  // 마우스 호버
  &:hover {
    cursor: pointer;

    // css 나온대로 쓰고
    background: #000000;

    background-image: linear-gradient(0deg, #000000, #000000),
      linear-gradient(180deg, #fff7e4 0%, #fde2ab 53.12%, #c89030 67.19%, #ffe9b9 92.71%, #4a2111 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    cursor: pointer;

    background: #383838;

    background-image: linear-gradient(0deg, #383838, #383838),
      linear-gradient(180deg, #fff7e4 0%, #fde2ab 53.12%, #c89030 67.19%, #ffe9b9 92.71%, #4a2111 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 비활성화
  &:disabled {
    box-shadow: none;
    background: #242424;

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffffff4d;
  }
`
const PopularWrap = styled.div`
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease, max-height 0.5s ease;

  ${props =>
    props.menuOpen &&
    `
    opacity: 1;
    visibility: visible;
    max-height: 1000px; /* 충분히 큰 값으로 설정하여 콘텐츠를 모두 보이도록 함 */
    transition: opacity 0.5s ease, visibility 0.5s ease, max-height 0.5s ease;
  `}

  ${SidebarWrap}:hover & {
    opacity: 1;
    visibility: visible;
    max-height: 1000px; /* 콘텐츠가 부드럽게 나타나도록 설정 */
  }
`
