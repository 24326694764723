import React, { useEffect, useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import Marquee from 'react-fast-marquee'
import { HiOutlineRefresh } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { withdrawCasinoMoney } from '../../api/memberManage/memberManageApi'
import MobileAuthorityAlertPopup from '../../containers/Popup/Error/MobileAuthorityAlertPopup'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { onClickMobileSignInHandlerInSideBar, onClickTitleHandler, onShowModalHandler } from '../../redux/modalSlice'
import { clearAllInfo } from '../../redux/sportsGameBettingSlice'
import isLogin, { setRecommendCode } from '../../utils/enums/authority'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import MobileBettingHistoryPage from '../mobileBettingHistory/MobileBettingHistoryPage'
import MobileCouponUseForm from '../mobileCouponUse/MobileCouponUseForm'
import MobileNoteList from '../mobileCustomCenter/MobileNote/MobileNoteList'
import MobileOnetoOneList from '../mobileCustomCenter/MobileOnetoOne/MobileOnetoOneList'
import MobileMoneyDepositForm from '../mobileMoneyDeposit/MobileMoneyDepositForm'
import MobileMoneyWithdrawalForm from '../mobileMoneyWithdrawal/MobileMoneyWithdrawalForm'
import MobileMyInfo from '../mobileMyInfo/MobileMyInfo'
import MobilePointExchangeForm from '../mobilePointExchange/MobilePointExchangeForm'
import MobileMenuSidebar from './MobileMenuSidebar'
import MobileSignInPopup from './MobileSignIn'
import MobileSignUpPopup from './MobileSignUp'
import CustomAlert from '../../containers/CustomAlert'
import CustomLoading from '../../containers/CustomLoading'

const MobileHeader = () => {
  const loginCheck = isLogin()
  const navigate = useNavigate()

  // 파트너 추천 코드 용 쿼리 스트링
  const [recParams] = useSearchParams()

  useEffect(() => {
    const rec = recParams.get('rec')

    if (!rec) {
      return
    }
    setRecommendCode(rec)
  }, [recParams])
  // redux 사용
  const dispatch = useDispatch()
  // 모바일 메뉴
  const [isMobileMenuSidebarShow, setIsMobileMenuSidebarShow] = useState(false)

  const changeMobileMenuSidebarShowHandler = () => {
    setIsMobileMenuSidebarShow(prev => !prev)
  }

  const { holdingMoney, totalGameMoney, holdingMemberPoint, noteRedisSize, oneToOneRedisSize, welcomeInfo } =
    useSelector(state => {
      const { memberInfo } = state

      return {
        noteRedisSize: memberInfo.noteRedisSize,
        oneToOneRedisSize: memberInfo.oneToOneRedisSize,
        holdingMoney: memberInfo.memberHoldingMoney,
        totalGameMoney: memberInfo.memberTotalGameMoney,
        holdingMemberPoint: memberInfo.memberHoldingPoint,
        welcomeInfo: memberInfo.welcomeInfo,
      }
    })

  const { modalTitle, sideMenuModalShow, mobileSignInShow } = useSelector(state => {
    const { modalInfo } = state

    return {
      modalTitle: modalInfo.modalTitle,
      sideMenuModalShow: modalInfo.modalShow,
      mobileSignInShow: modalInfo.mobileSignInShow,
    }
  })

  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
    if (mobileSignInShow) {
      dispatch(onClickMobileSignInHandlerInSideBar(false))
    }
  }

  // 내정보 모달
  const [myPageModalShow, setMyPageModalShow] = useState(false)
  const onShowMyPageModal = () => {
    setMyPageModalShow(true)
  }
  const onCloseMyPageModal = () => {
    setMyPageModalShow(false)
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onShowSignUpModal = () => {
    setSignUpModalShow(true)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  useEffect(() => {
    if (!isLogin()) return

    dispatch(fetchMemberInfoAction())

    const intervalId = setInterval(() => {
      dispatch(fetchMemberInfoAction())
    }, 5000)

    return () => {
      clearInterval(intervalId) // 컴포넌트 언마운트 시 인터벌 정리
    }
  }, [loginCheck])

  const modalClickHandler = e => {
    if (!isLogin()) {
      setSignInModalShow(true)
      return
    }
    dispatch(onClickTitleHandler(e))
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const renderModal = title => {
    switch (title) {
      case 'MYPAGE':
        return (
          <MobileMyInfo
            visible={sideMenuModalShow}
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'MONEYDEPOSIT':
        return (
          <MobileMoneyDepositForm
            visible={sideMenuModalShow}
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'MONEYWITHDRAWAL':
        return (
          <MobileMoneyWithdrawalForm
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'POINTEXCHANGE':
        return (
          <MobilePointExchangeForm
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'COUPONUSE':
        return (
          <MobileCouponUseForm
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'NOTE':
        return (
          <MobileNoteList
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'ONETOONE':
        return (
          <MobileOnetoOneList
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'BETTING':
        return (
          <MobileBettingHistoryPage
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      default:
        return null
    }
  }
  useEffect(() => {
    if (sideMenuModalShow) {
      // 현재 스크롤 위치를 저장합니다.
      const { scrollY } = window
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
    } else {
      // 저장된 스크롤 위치를 복원합니다.
      const savedScrollY = parseInt(document.body.style.top || '0', 10)
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, savedScrollY * -1)
    }
  }, [sideMenuModalShow])

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  // 경고창 용 팝업
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }

  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)
  const onClickCasinoMoneyWithdrawHandler = () => {
    if (!isLogin()) {
      return
    }
    if (moneyExchangeLoading) {
      onChangeAlertPopupMessageHandler('카지노 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (totalGameMoney <= 0) return

    setMoneyExchangeLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'GAME-1014':
            onChangeAlertPopupMessageHandler('카지노 머니 동기화중입니다. \n잠시후에 다시 시도해주세요.')
            break
          case 'ASSET-7001':
            onChangeAlertPopupMessageHandler('잠시후 다시 시도해주세요.')
            break
          default:
            onChangeAlertPopupMessageHandler('처리 실패했습니다.')
        }
      })
      .finally(() => {
        setMoneyExchangeLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  const onClickMenuModalHandler = title => {
    if (!isLogin()) {
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  const onClickOpenModalHandler = title => {
    if (!isLogin()) {
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }
  return (
    <>
      {moneyExchangeLoading && <CustomLoading />}
      {alertPopupShow && <CustomAlert info={alertPopupMessage} onClickCloseHandler={onCloseAlertPopup} />}
      {modalTitle && renderModal(modalTitle)}
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 해당 페이지로 이동합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
          isNoteInfo
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
          isOneToOneInfo
        />
      )}
      {signInModalShow && (
        <MobileSignInPopup
          visible={signInModalShow || mobileSignInShow}
          onClose={onCloseSignInModal}
          onShowSignUpModal={onShowSignUpModal}
        />
      )}
      {mobileSignInShow && (
        <MobileAuthorityAlertPopup
          visible={mobileSignInShow}
          onClose={() => dispatch(onClickMobileSignInHandlerInSideBar(false))}
        />
      )}
      {/* {myPageModalShow && <MobileMyInfo visible={myPageModalShow} onClose={onCloseMyPageModal} />} */}
      {/* {myPageModalShow && <MobileMyInfo visible={myPageModalShow} onClose={onCloseMyPageModal} />} */}
      {signUpModalShow && <MobileSignUpPopup visible={signUpModalShow} onClose={onCloseSignUpModal} />}
      <MobileHeaderWrap>
        <MobileHeaderInnerWrap>
          <MobileMenuButtonWrap onClick={changeMobileMenuSidebarShowHandler}>
            <MenuButtonIcon />
            <MobileMenuText>메뉴</MobileMenuText>
          </MobileMenuButtonWrap>
          <MobileHeaderLogoImg
            src={`${process.env.PUBLIC_URL}/img/main/Logo.png`}
            style={!isLogin() ? { marginLeft: '-60px' } : { marginLeft: '0px' }}
            alt=""
            onClick={e => {
              navigate('/')
            }}
          />
          <MobileHeaderButtonWrap>
            {isLogin() ? (
              <MobileHeaderProfileImg
                src={`${process.env.PUBLIC_URL}/img/header/mobile_profile.png`}
                alt=""
                onClick={e => {
                  dispatch(clearAllInfo())
                  dispatch(
                    onShowModalHandler({
                      isModalShow: true,
                      title: 'MYPAGE',
                    }),
                  )
                }}
              />
            ) : (
              <>
                <SignButton
                  onClick={e => {
                    setSignInModalShow(true)
                  }}
                >
                  <SignButtonInfo>로그인</SignButtonInfo>
                </SignButton>
                <SignUpButton
                  onClick={e => {
                    setSignUpModalShow(true)
                  }}
                >
                  <SignUpButtonInfo>회원가입</SignUpButtonInfo>
                </SignUpButton>
              </>
            )}
          </MobileHeaderButtonWrap>
        </MobileHeaderInnerWrap>
        <MarqueeWrap>
          <Marquee>
            <img
              src={`${process.env.PUBLIC_URL}/img/main/speaker.png`}
              style={{ width: '22px', height: 'auto' }}
              alt=""
            />
            &nbsp;{welcomeInfo}&nbsp;&nbsp;
          </Marquee>
        </MarqueeWrap>
        <MobileAssetWrap>
          <MobileAssetSportsMoney>
            <span>스포츠 캐쉬</span>
            <span style={{ color: `${HermesMainText}` }}>{holdingMoney?.toString().replace(commonReg2, ',')} 원</span>
          </MobileAssetSportsMoney>
          <MobileAssetCasinoMoney>
            <CasinoMoneyWithdrawBox onClick={onClickCasinoMoneyWithdrawHandler}>
              <CustomHiOutlineRefresh />
              <span>회수</span>
            </CasinoMoneyWithdrawBox>
            <span>카지노 캐쉬</span>
            <span style={{ color: `${HermesMainText}` }}>{totalGameMoney?.toString().replace(commonReg2, ',')} 원</span>
          </MobileAssetCasinoMoney>
        </MobileAssetWrap>
        <MobileAssetWrap>
          <MobileAssetSportsMoney
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onClickMenuModalHandler('POINTEXCHANGE')
            }}
          >
            <span>보유 포인트</span>
            <span style={{ color: `${HermesMainText}` }}>
              {holdingMemberPoint?.toString().replace(commonReg2, ',')} 원
            </span>
            <CasinoMoneyWithdrawBox
              onClick={() => {
                onClickMenuModalHandler('POINTEXCHANGE')
              }}
            >
              <CustomHiOutlineRefresh />
              <span>전환</span>
            </CasinoMoneyWithdrawBox>
          </MobileAssetSportsMoney>
        </MobileAssetWrap>
      </MobileHeaderWrap>
      {isMobileMenuSidebarShow && (
        <MobileMenuSidebar
          isMobileMenuSidebarShow={isMobileMenuSidebarShow}
          changeMobileMenuSidebarShowHandler={changeMobileMenuSidebarShowHandler}
        />
      )}
      {isMobileMenuSidebarShow && <Overlay />}
    </>
  )
}

export default MobileHeader

const CustomSpan = styled.span`
  position: absolute;
  color: ${HermesMainText};
  right: 60px;
  font-size: 18px;
  font-weight: 600;
`

const MarqueeWrap = styled.div`
  width: 100%;
  height: 35px;
  padding: 10px 0px;
  font-weight: bold;
  color: ${HermesMainText};
  position: relative;
  font-size: 12px;
`

const CasinoMoneyWithdrawBox = styled.div`
  display: flex;
  min-width: max-content;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  margin-top: -3px;
  &:hover {
    color: ${HermesMainText};
    span {
      color: ${HermesMainText};
    }
  }
  span {
    font-size: 13px;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 2px;
    color: #b2b2b2;
    &:hover {
      color: ${HermesMainText} !important;
    }
  }
`

// 카지노 머니 회수 아이콘
const CustomHiOutlineRefresh = styled(HiOutlineRefresh)`
  width: 15px !important;
  height: auto;
  cursor: pointer;
  color: ${HermesMainText} !important;
`

const MobileAssetWrap = styled.div`
  width: 100%;

  height: 35px;
  display: flex;
  justify-content: space-between;
  background: rgb(29, 30, 33);
  padding: 3px 10px;
`

const MobileAssetSportsMoney = styled.div`
  // width: 100%;
  display: flex;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  gap: 4px;
`

const MobileAssetCasinoMoney = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  gap: 4px;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); // 검은색 배경에 70% 투명도
  z-index: 999; // 다른 요소들 위에 나타나도록 z-index 설정
`

// mobile header wrap
const MobileHeaderWrap = styled.div`
  background: rgb(22, 23, 26);
  transition: background-color 0.5s linear;

  // padding: 5px 0px;

  position: fixed;
  top: 0;

  width: 100%;

  height: 145px;

  z-index: 500;

  display: flex;
  flex-direction: column;
  align-items: center;
`

// mobile header inner Wrap
const MobileHeaderInnerWrap = styled.div`
  position: relative;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  height: 100%;
`

// mobile header logo
const MobileHeaderProfileImg = styled.img`
  width: 32px;
  height: 32px;
`

const MobileHeaderLogoImg = styled.img`
  width: auto;
  height: 40px;

  position: absolute;
  left: 50%;
  top: 11px;
  transform: translateX(-50%);

  // border: 1px solid green;

  &:hover {
    cursor: pointer;
  }
`

const MobileHeaderButtonWrap = styled.div`
  width: min-content;
  height: 100%;

  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;

  svg {
    color: rgb(112, 116, 120);
    width: 35px;
    height: 35px;
  }
`

const SignButtonInfo = styled.span`
  line-height: 22px;
  font-weight: 600;
  color: ${HermesMainText};
`

const SignButton = styled.button`
  width: 80px;
  height: 38px;
  border-radius: 6px !important;
  outline: none;

  border: 2px solid transparent;
  box-shadow: 0px 0px 3px 0px #000000 inset;

  // Colors - 위아래 순서 바꾸기!!
  background-image: linear-gradient(180deg, #262626 23.07%, #262626 91.02%),
    linear-gradient(to right, #7b4c1d, #fdda2a, #7b4c1d);
  background-origin: border-box;
  background-clip: content-box, border-box;
`

const SignUpButton = styled.button`
  width: 80px;
  height: 38px;
  border-radius: 6px !important;
  outline: none;

  border: 2px solid transparent;
  box-shadow: 0px 0px 3px 0px #000000 inset;

  // Colors - 위아래 순서 바꾸기!!
  background-image: linear-gradient(180deg, #262626 23.07%, #262626 91.02%),
    linear-gradient(to right, #7b4c1d, #fdda2a, #7b4c1d);
  background-origin: border-box;
  background-clip: content-box, border-box;
`

// const SignButton = styled(ButtonH40)`
//   width: 75px;
//   height: 35px;

//   // 마우스 호버
//   &:hover {
//     ${SignButtonInfo} {
//       color: #ffc01a;
//     }
//   }

//   // 클릭
//   &:focus,
//   &:active,
//   &active:focus {
//     ${SignButtonInfo} {
//       color: #ffc01a;
//     }
//   }

//   // 비활성화
//   &:disabled {
//     ${SignButtonInfo} {
//       color: #ffffff4d;
//     }
//   }
// `

const SignUpButtonInfo = styled.span`
  font-style: normal;
  line-height: 22px;
  font-weight: 600;
  color: ${HermesMainText};
`

// 모바일 메뉴 버튼 wrap
const MobileMenuButtonWrap = styled.div`
  width: min-content;
  height: 100%;
  width: 13%;
  display: flex;
  align-items: center;
  margin-right: auto;

  &:hover {
    cursor: pointer;
  }
`

// 모바일 메뉴 아이콘
const MenuButtonIcon = styled(GiHamburgerMenu)`
  color: ${HermesMainBorder};
  width: 20px;
  height: 20px;
`
const MobileMenuText = styled.div`
  color: ${HermesMainBorder};
  font-weight: 600;
  font-size: 12px;
`
