import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import Marquee from 'react-fast-marquee'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { HermesMainBorder, HermesMainHover, HermesMainHover2, HermesMainText } from '../../utils/palette'
import isLogin from '../../utils/enums/authority'
import { onShowModalHandler } from '../../redux/modalSlice'
import AuthorityAlertPopup from '../Popup/Error/AuthorityAlertPopup'
import CustomAlert from '../CustomAlert'

const MainPageCarousel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [carouselList, setCarouselList] = useState([`${process.env.PUBLIC_URL}/img/main/banner02.png`])

  const { oneToOneRedisSize, noteRedisSize, welcomeInfo } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      welcomeInfo: memberInfo.welcomeInfo,
    }
  })

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)
  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    navigate(link)
  }
  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <AuthorityAlertPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      <MainPageCarouselBox>
        <MarqueeWrap>
          <Marquee>
            <img
              src={`${process.env.PUBLIC_URL}/img/main/speaker.png`}
              style={{ width: '22px', height: 'auto' }}
              alt=""
            />
            &nbsp;&nbsp;{welcomeInfo}
          </Marquee>
        </MarqueeWrap>
        <div style={{ display: 'flex' }}>
          <MainPageCarouselLeftItemBox>
            <MainPageCarouselLeftItem>
              <img src={`${process.env.PUBLIC_URL}/img/main/banner01.png`} alt="" />
              <MainPageCarouselBtnBox
                onClick={e => {
                  onClickMenuHandler('/customcenter/event')
                }}
              >
                <MainPageCarouselBtn>자세히</MainPageCarouselBtn>
              </MainPageCarouselBtnBox>
            </MainPageCarouselLeftItem>
          </MainPageCarouselLeftItemBox>

          <MainPageCarouselRightItemBox>
            <MainPageCarouselLeftItem>
              <img src={`${process.env.PUBLIC_URL}/img/main/banner02.png`} alt="" />
              <MainPageCarouselBtnBox
                onClick={e => {
                  onClickMenuHandler('/customcenter/event')
                }}
              >
                <MainPageCarouselBtn>자세히</MainPageCarouselBtn>
              </MainPageCarouselBtnBox>
            </MainPageCarouselLeftItem>
          </MainPageCarouselRightItemBox>
        </div>
      </MainPageCarouselBox>
    </>
  )
}

export default MainPageCarousel

const MarqueeWrap = styled.div`
  width: 100%;
  height: 40px;
  padding: 15px 0px;
  font-weight: bold;
  color: ${HermesMainText};
  position: relative;
  font-size: 14px;
`

const MobileCarousel = styled(Carousel)`
  width: 100%;
  height: auto;
  position: relative;

  .carousel-indicators {
    .active {
      background-clip: initial !important;
      margin-right: 5px;
      background-color: ${HermesMainHover2} !important;
      padding: 3px !important;
    }

    button {
      width: 10px;
      height: 10px;
      margin-top: 0 !important;
      background: none !important;
      border: 1px solid ${HermesMainHover} !important;
      background-clip: border-box !important;
      background-clip: initial !important;
      border-radius: 50%;
      margin-right: 5px;
      padding: 3px !important;
      outline: none !important;
    }
  }

  &:hover {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      opacity: 1;
    }
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='orange' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    opacity: 0;
    position: absolute;
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='orange' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    opacity: 0;
    position: absolute;
  }
`

const MobileCarouselItem = styled(Carousel.Item)``

const MainPageCarouselBox = styled.div`
  // margin-top: 44px;
  display: flex;
  flex-direction: column;
  width: 100%:
  position: relative;
`

const MainPageCarouselLeftItemBox = styled.div`
  padding-right: 12px;
  width: 50%;
`
const MainPageCarouselLeftItem = styled.div`
  position: relative;
  width: 100%;

  img {
    border-radius: 6px;
    overflow-clip-margin: content-box;
    overflow: clip;
    width: 100%;
    height: 264px;
  }
`

const MainPageCarouselRightItemBox = styled.div`
  padding-left: 12px;
  width: 50%;
`

const MainPageCarouselRightItem = styled.div`
  position: relative;
  width: 100%;
  img {
    border-radius: 6px;
    overflow-clip-margin: content-box;
    overflow: clip;
    width: 100%;
    height: 264px;
  }
`

const MainPageCarouselBtnBox = styled.div`
  position: absolute;
  left: 0;
  bottom: 25px;
  z-index: 99;
`

const MainPageCarouselBtn = styled.div`
  padding: 6px 30px;
  display: inline-block;
  margin: 16px 0 0 35px;
  color: #fff;
  cursor: pointer;
  border: 1px solid ${HermesMainHover2};
  border-radius: 16px;
  font-size: 14px;

  &:hover {
    background-color: ${HermesMainHover2};
  }
`
