import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { searchAnswerCompleteOneToOne, searchNoReadNoteCount } from '../api/customCenter/customCenterApi'

// 유저 데이터
export const fetchMemberInfoAction = createAction('FETCH_MEMBER_INFO')

// 재발급
export const reAuthenticationAction = createAction('RE_AUTHENTICATION')

// 홈 init
export const initHomeInfoAction = createAction('INIT_HOME_INFO')

// 팝업 init
export const initPopupInfoAction = createAction('INIT_POPUP_INFO')

// 스포츠 init
export const initSportsInfoAction = createAction('INIT_SPORTS_INFO')

// 가상게임 init
export const initVirtualInfoAction = createAction('INIT_VIRTUAL_INFO')

// 유저 로그 생성용
export const createUserLogAction = createAction('CREATE_USER_LOG')

export const fetchNoteSize = createAsyncThunk('memberInfo/fetchNoteSize', async () => {
  const res = await searchNoReadNoteCount()
  return res.data
})

export const fetchOneToOneSize = createAsyncThunk('memberInfo/fetchOneToOneSize', async () => {
  const res = await searchAnswerCompleteOneToOne()
  return res.data
})

const checkVisible = title => {
  const check = localStorage.getItem(`popup-${title}`)
  const today = new Date().getDate()
  if (!check) {
    return true
  }
  return Number(check) !== today
}

const memberInfoSlice = createSlice({
  name: 'memberInfo',
  initialState: {
    homeInitLoading: false,
    memberHoldingMoney: 0,
    memberTotalGameMoney: 0,
    holdemMoneyAmount: 0,
    memberHoldingPoint: 0,
    noteRedisSize: 0,
    oneToOneRedisSize: 0,
    userId: '',
    userName: '',
    bankName: '',
    accountNum: '',
    accountHolder: '',
    depositLevel: '',
    miniGameBettingInfo: {},
    sportsBettingInfo: {},
    noteMessageInfo: '',
    reloadTime: 0,
    reloadCount: 0,
    currentAccessGame: false,
    tempCurrentAccessGame: false,
    realTimeDepositList: [],
    realTimeWithdrawalList: [],
    popupList: [],
    domesticSportsUsing: false,
    domesticSportsName: '국내형',
    overseasSportsUsing: false,
    overseasSportsName: '해외형',
    specialSportsUsing: false,
    specialSportsName: '스페셜',
    liveSportsUsing: false,
    liveSportsName: '라이브',
    revolutionHoldemInfo: {},
    wildHoldemInfo: {},
    attendanceUse: false,
    welcomeInfo: '',
  },
  reducers: {
    setHomeInitLoading: (state, action) => {
      state.homeInitLoading = action.payload
    },
    setUserInfo: (state, action) => {
      const {
        edgeName,
        userId,
        userName,
        nickName,
        bankName,
        accountNum,
        accountHolder,
        depositLevel,
        moneyAmount,
        holdemMoneyAmount,
        rollingPointAmount,
        losingPointAmount,
        casinoMoneyAmount,
        miniGameMinBetting,
        miniGameMaxBetting,
        miniGameMaxWinning,
        noteRedisSize,
        oneToOneRedisSize,
        reloadCount,
        reloadTime,
        currentAccessGame,
        welcomeInfo,
      } = action.payload

      state.memberHoldingMoney = moneyAmount
      state.memberTotalGameMoney = casinoMoneyAmount
      state.holdemMoneyAmount = holdemMoneyAmount
      state.memberHoldingPoint = rollingPointAmount
      state.userId = userId
      state.userName = userName
      state.nickName = nickName
      state.bankName = bankName
      state.accountNum = accountNum
      state.accountHolder = accountHolder
      state.depositLevel = depositLevel
      state.welcomeInfo = welcomeInfo
      state.miniGameBettingInfo = {
        miniGameMinBetting,
        miniGameMaxBetting,
        miniGameMaxWinning,
      }

      state.noteRedisSize = noteRedisSize ?? 0
      state.oneToOneRedisSize = oneToOneRedisSize ?? 0

      state.currentAccessGame = currentAccessGame ?? false
      state.reloadCount = reloadCount ?? 0
      state.reloadTime = reloadTime ?? 0
    },
    setHomeInit: (state, action) => {
      state.realTimeDepositList = action.payload.realTimeDepositList
      state.realTimeWithdrawalList = action.payload.realTimeWithdrawalList
      state.domesticSportsUsing = action.payload.domesticSportsUsing
      state.domesticSportsName = action.payload.domesticSportsName
      state.overseasSportsUsing = action.payload.overseasSportsUsing
      state.overseasSportsName = action.payload.overseasSportsName
      state.specialSportsUsing = action.payload.specialSportsUsing
      state.specialSportsName = action.payload.specialSportsName
      state.liveSportsUsing = action.payload.liveSportsUsing
      state.liveSportsName = action.payload.liveSportsName
      state.revolutionHoldemInfo = action.payload.revolutionHoldemInfo
      state.wildHoldemInfo = action.payload.wildHoldemInfo
      state.attendanceUse = action.payload.attendanceUse
      state.welcomeInfo = action.payload.welcomeInfo
    },
    setRealTimeData: (state, action) => {
      state.realTimeDepositList = action.payload.realTimeDepositList
      state.realTimeWithdrawalList = action.payload.realTimeWithdrawalList
    },
    // 팝업 초기 데이터
    setPopupInitData: (state, action) => {
      state.popupList = action.payload.content.map(item => {
        return {
          title: item.title,
          imgName: item.imgName,
          visible: checkVisible(item.title),
        }
      })
    },
    // 팝업 상태 변경
    updatePopupData: (state, action) => {
      const title = action.payload
      const prevPopupList = state.popupList
      state.popupList = prevPopupList.map(item => (item.title === `${title}` ? { ...item, visible: false } : item))
    },
    setTempCurrentAccessGame: (state, action) => {
      // 접속: true, 미접속: false
      state.tempCurrentAccessGame = action.payload
    },

    setNoteMessageInfo: (state, action) => {
      state.noteMessageInfo = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchNoteSize.fulfilled, (state, action) => {
      const { noteSize } = action.payload
      state.noteRedisSize = noteSize
    })
    builder.addCase(fetchOneToOneSize.fulfilled, (state, action) => {
      const { oneToOneSize } = action.payload
      state.oneToOneRedisSize = oneToOneSize
    })
  },
})

export const {
  setHomeInitLoading,
  setUserInfo,
  setHomeInit,
  setRealTimeData,
  setPopupInitData,
  updatePopupData,
  setNoteMessageInfo,
  setTempCurrentAccessGame,
} = memberInfoSlice.actions

export default memberInfoSlice.reducer
