import { createAction, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const fetchVirtualFixtureListAction = createAction('FETCH_VIRTUAL_FIXTURE_LIST')

const virtualGameInfoSlice = createSlice({
  name: 'virtualGameInfo',
  initialState: {
    virtualGameLoading: false,
    virtualGameParams: {
      userId: '',
      sportType: null,
    },
    virtualSportList: [], // 리그정보 포함
    virtualFixtures: [],
    virtualMarketInfos: [],
    virtualResultHistory: [],
    virtualBetLogs: [],
  },
  reducers: {
    setVirtualInitInfo: (state, action) => {
      state.virtualSportList = action.payload
    },
    setVirtualGameLoading: (state, action) => {
      state.virtualGameLoading = action.payload
    },

    // 파람
    setVirtualGameParam: (state, action) => {
      const { userId, sportType } = action.payload

      state.virtualGameParams = {
        userId,
        sportType,
      }
    },

    setResetVirtualFixtures: (state, action) => {
      state.virtualFixtures = []
    },

    setVirtualFixtures: (state, action) => {
      const now = moment().tz('Asia/Seoul')

      const currentGame = action.payload

      state.virtualFixtures = currentGame.map(fixture => {
        const target = moment(fixture.startDate)
        const duration = moment.duration(target.diff(now))

        let remainMins = Math.floor(duration.asMinutes())
        let remainSecs = Math.floor(duration.asSeconds() % 60)

        if (duration.asSeconds() <= 0) {
          remainMins = 0
          remainSecs = 0
        }

        return {
          ...fixture,
          remainMins,
          remainSecs,
        }
      })
    },

    setVirtualMarketInfos: (state, action) => {
      const marketInfos = action.payload

      state.virtualMarketInfos = marketInfos
    },

    setVirtualResultHistory: (state, action) => {
      const resultHistory = action.payload

      state.virtualResultHistory = resultHistory
    },
    setVirtualBetLogs: (state, action) => {
      const betLogs = action.payload

      state.virtualBetLogs = betLogs
    },
  },
})

export const {
  setVirtualInitInfo,
  setVirtualGameLoading,
  setVirtualGameParam,
  setResetVirtualFixtures,
  setVirtualFixtures,
  setVirtualMarketInfos,
  setVirtualResultHistory,
  setVirtualBetLogs,
} = virtualGameInfoSlice.actions

export default virtualGameInfoSlice.reducer
