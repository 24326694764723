import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { VirtualGameLeagueTypeKr } from '../../../utils/enums/VirtualGame/VirtualGameEnums'
import { HermesMainHover2 } from '../../../utils/palette'

const MobileVirtualSoccerContentTop = () => {
  const [leagueList, setLeagueList] = useState([])

  const [activeLeagueType, setActiveLeagueType] = useState(null)

  const onClickActiveLeagueTypeHandler = league => {
    setActiveLeagueType(league)
  }

  const { sportType } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      sportType: virtualGameBetting.selectedVirtuaGameSportType,
    }
  })
  const { allLeagueList } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      allLeagueList: virtualGameInfo.virtualSportList,
    }
  })

  useEffect(() => {
    if (sportType !== 'VIRTUAL_SOCCER') return

    setLeagueList(
      allLeagueList.find(item => item.sportType === sportType)?.leagueList.filter(item => item.leagueIsShow) ?? [],
    )
  }, [allLeagueList, sportType])

  useEffect(() => {
    if (leagueList.length === 0) return

    if (activeLeagueType) return

    setActiveLeagueType(leagueList[0].leagueType)
  }, [activeLeagueType, leagueList])

  const wrapBoxRef = useRef(null)
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (wrapBoxRef.current) {
      const width = wrapBoxRef.current.offsetWidth

      const height = Math.round((width / 16) * 9) // 16:9 비율로 높이 계산
      setVideoSize({ width, height })
    }
  }, [wrapBoxRef.current])

  const getLeagueVideoCompo = () => {
    const { width, height } = videoSize

    switch (activeLeagueType) {
      case 'VIRTUAL_PREMIERSHIP':
        return (
          <VirtualGameVideo
            src={`https://b1.nutv265.com/ios/?vn=1&sw=${width}&sh=${height}`}
            style={{ width: '100%', height: `${height}px` }}
          />
        )
      case 'VIRTUAL_SUPERLEAGUE':
        return (
          <VirtualGameVideo
            src={`https://b1.nutv265.com/ios/?vn=2&sw=${width}&sh=${height}`}
            style={{ width: '100%', height: `${height}px` }}
          />
        )
      case 'VIRTUAL_EURO_CUP':
        return (
          <VirtualGameVideo
            src={`https://b1.nutv265.com/ios/?vn=13&sw=${width}&sh=${height}`}
            style={{ width: '100%', height: `${height}px` }}
          />
        )
      case 'VIRTUAL_WORLD_CUP':
        return (
          <VirtualGameVideo
            src={`https://b1.nutv265.com/ios/?vn=3&sw=${width}&sh=${height}`}
            style={{ width: '100%', height: `${height}px` }}
          />
        )
      default:
        return null
    }
  }

  return (
    <VirtualGameContentTopWrap>
      <VirtualGameVideoWrap>
        <VirtualGameVideoInnerWrap>
          <VirtualGameVideoInnerWrapHeader count={leagueList.length}>
            {leagueList.length === 0 ? (
              <div
                style={{
                  width: '100%',
                  height: '400px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '20px',
                  color: 'white',
                }}
              >
                영상을 지원하지 않습니다.
              </div>
            ) : (
              <>
                {leagueList.map(leagueItem => {
                  return (
                    <VirtualGameVideoInnerWrapHeaderTab
                      active={activeLeagueType === leagueItem.leagueType}
                      onClick={() => {
                        onClickActiveLeagueTypeHandler(leagueItem.leagueType)
                      }}
                    >
                      {VirtualGameLeagueTypeKr[leagueItem.leagueType]}
                    </VirtualGameVideoInnerWrapHeaderTab>
                  )
                })}
              </>
            )}
          </VirtualGameVideoInnerWrapHeader>

          <VirtualGameVideoInnerWrapBox ref={wrapBoxRef}>{getLeagueVideoCompo()}</VirtualGameVideoInnerWrapBox>
        </VirtualGameVideoInnerWrap>
      </VirtualGameVideoWrap>
    </VirtualGameContentTopWrap>
  )
}

export default MobileVirtualSoccerContentTop

const VirtualGameContentTopWrap = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

const VirtualGameVideoWrap = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 3px;
`

const VirtualGameVideoInnerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const VirtualGameVideoInnerWrapHeader = styled.div`
  width: 100%;
  background: rgb(22, 23, 26);

  display: grid;
  grid-template-columns: ${props =>
    props.count !== 0 ? `repeat(${props.count}, minmax(0, 1fr))` : 'repeat($1, minmax(0, 1fr))'};
`
const VirtualGameVideoInnerWrapHeaderTab = styled.div`
  float: left;
  // width: 25%;
  padding: 14px 0;
  color: #fff;
  cursor: pointer;
  transition: color 0.1s ease-out 0s;

  font-weight: 700;

  ${props =>
    props.active &&
    `
        color: ${HermesMainHover2};
    `}
`

const VirtualGameVideoInnerWrapBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const VirtualGameVideo = styled.iframe``
