import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { FiRefreshCw } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMiniGameBettingLog, searchMiniGameInfo } from '../../../api/game/miniGameApi'
import { formatSeconds, getCurrentTime } from '../../../containers/MiniGame/miniGameTimeUtils'
import { BettingWhatLockIcon } from '../../../containers/MiniGame/Ntry/EosPowerBallFive'
import {
  addBettingCart,
  setMiniGameInfo,
  setBettingCloseHandler,
  setMiniGameCompleteFlag,
  setResetData,
} from '../../../redux/miniGameBettingSlice'
import { MiniGameTypeEnums } from '../../../utils/enums/MiniGame/PowerBallEnums'
import MobileMiniGameBettingLogData from '../MobileMiniGameBettingLogData'
import MobileMiniGameTabs from '../MobileMiniGameTabs'
import MobileNtryTabs from './MobileNtryTabs'

const MobileSpeedKeno = () => {
  // redux 사용
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const [params, setParams] = useState({
    miniGameType: 'NTRY_SPEED_KENO',
  })

  const {
    miniGameType,
    miniGameBettingInfo,
    miniGameRound,
    deadLineTime,
    isShow,
    endTime,
    bettingCountOkFlag,
    bettingCloseFlag,
    bettingCompleteFlag,
  } = useSelector(state => {
    const { miniGameBetting } = state

    return {
      miniGameType: miniGameBetting.miniGameType,
      miniGameBettingInfo: miniGameBetting.miniGameBettingInfo,
      miniGameRound: miniGameBetting.miniGameRound,
      deadLineTime: miniGameBetting.deadLineTime,
      isShow: miniGameBetting.isShow,
      endTime: miniGameBetting.endTime,
      bettingCountOkFlag: miniGameBetting.bettingCountOkFlag,
      bettingCloseFlag: miniGameBetting.bettingCloseFlag,
      bettingCompleteFlag: miniGameBetting.bettingCompleteFlag,
    }
  })

  // 서버 시간 == 현재 시간
  const [currentTime, setCurrentTime] = useState(null)

  const intervalRef = useRef(null)
  // svt : serverTime , stt : startTime
  const getCurrentTimeDifference = deadLine => {
    if (!currentTime)
      return {
        minutes: 0,
        seconds: 0,
      }

    let totalSeconds = endTime - currentTime - deadLine

    if (totalSeconds < 0) {
      totalSeconds = 0
    }

    return {
      minutes: Math.floor(totalSeconds / 60),
      seconds: totalSeconds % 60,
    }
  }

  const [time, setTime] = useState({
    minutes: 0,
    seconds: 0,
  })

  const fetchSearchMiniGameInfo = async () => {
    await searchMiniGameInfo(params)
      .then(res => {
        dispatch(
          setMiniGameInfo({
            miniGameType: res.data.miniGameType,
            miniGameBettingInfo: JSON.parse(res.data.miniGameBettingPer),
            round: res.data.round,
            bettingCountOkFlag: res.data.bettingCountOkFlag,
            endTime: res.data.endTime,
            deadLineTime: res.data.deadLineTime,
            isShow: res.data.isShow,
          }),
        )

        setCurrentTime(res.data.serverTime)

        setTime(getCurrentTimeDifference(res.data.deadLineTime))

        if (!bettingCompleteFlag) {
          dispatch(setResetData())
        }
      })
      .catch(error => {})
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchMiniGameInfo()

    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentTime >= endTime) {
        fetchSearchMiniGameInfo()
        dispatch(setMiniGameCompleteFlag(false))
        dispatch(setResetData())
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [currentTime, endTime])

  const updateTimer = () => {
    setCurrentTime(getCurrentTime())
    setTime(getCurrentTimeDifference(deadLineTime))
  }

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(updateTimer, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [time])

  /**
   *  배팅 조회
   */
  const [page, setPage] = useState(0)

  const [miniGameBettingLogParams, setMiniGameBettingLogParams] = useState({
    miniGameType: 'NTRY_SPEED_KENO',
    page,
    size: 5,
  })

  useEffect(() => {
    setMiniGameBettingLogParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMiniGameBettingLog = () => {
    searchMiniGameBettingLog(miniGameBettingLogParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
      })
      .catch(error => {})
  }

  useEffect(() => {
    fetchSearchMiniGameBettingLog()
  }, [miniGameBettingLogParams, bettingCompleteFlag])

  const [miniGameBettingLogData, setMiniGameBettingLogData] = useState(MobileMiniGameBettingLogData(content))
  useEffect(() => {
    setMiniGameBettingLogData(MobileMiniGameBettingLogData(content))
  }, [content])

  const [rows, setData] = useState(miniGameBettingLogData.tableRowsData)
  useEffect(() => {
    setData(miniGameBettingLogData.tableRowsData)
  }, [miniGameBettingLogData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: 5,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
  }

  const isDisabled = time.minutes === 0 && time.seconds === 0

  // 배팅 마감일 때 배팅카트 초기화
  useEffect(() => {
    dispatch(setBettingCloseHandler(isDisabled))
  }, [isDisabled])

  // 배팅 카트 담기
  const handleBettingCart = event => {
    const miniGameBettingPer = event.currentTarget.getAttribute('name') || 0
    const miniGameBettingType = event.currentTarget.id

    dispatch(
      addBettingCart({
        miniGameBettingPer,
        miniGameBettingType,
      }),
    )
  }

  return (
    <>
      <MobileMiniGameTabs activeKey="1" />
      <MobileNtryTabs activeKey="4" />
      <CustomerCenterWrap>
        <MiniGameContentWrap>
          {!isShow && (
            <InspectOverlay>
              <BiLock />
              <span>점검중</span>
            </InspectOverlay>
          )}
          <MiniGameIframeWrap
            style={{
              background: `url(${process.env.PUBLIC_URL}/img/main/97437ad.jpg) no-repeat center center`,
            }}
          >
            {!loading ? (
              <MiniGameIframe
                src="https://ntry.com/scores/speedkeno/live_mobile.php"
                title="SPEEDKENO"
                // scrolling="no"
                // frameBorder="0"
              />
            ) : (
              <motion.div
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0.5, scale: 0.1 }}
                transition={{ duration: 0.6 }}
              >
                <IframeOverlay>
                  <HeaderLogoImg
                    src={`${process.env.PUBLIC_URL}/img/main/iframeLogo.png`}
                    alt=""
                    onClick={e => {
                      window.location.href = '/'
                    }}
                  />
                </IframeOverlay>
              </motion.div>
            )}
          </MiniGameIframeWrap>

          <MiniGameBettingSortWrap>
            {(isDisabled || !bettingCountOkFlag) && (
              <Overlay>
                <BettingWhatLockIcon />
              </Overlay>
            )}
            <MiniGameBettingSortHeaderWrap>
              <MiniGameBettingSortHeaderInfo>
                <MiniGameBettingSortHeaderLabel>회차</MiniGameBettingSortHeaderLabel>
                <MiniGameBettingSortHeaderText>{miniGameRound || '-'} 회차</MiniGameBettingSortHeaderText>
              </MiniGameBettingSortHeaderInfo>
              <MiniGameBettingSortHeaderInfo>
                <MiniGameBettingSortHeaderLabel>추첨까지</MiniGameBettingSortHeaderLabel>
                <MiniGameBettingSortHeaderText>
                  {time.minutes} : {formatSeconds(time.seconds)}
                </MiniGameBettingSortHeaderText>
              </MiniGameBettingSortHeaderInfo>
            </MiniGameBettingSortHeaderWrap>

            {/* 배팅 종목 */}
            <MiniGameBettingSortCartWrap>
              {/* 결과  */}
              <MiniGameBettingSortCartGroupWrapSecond>
                홀짝은 숫자합의 마지막 숫자를 기준으로 하며, <br />
                언오버는 숫자합의 마지막 숫자 4.5 기준으로 판정합니다.
              </MiniGameBettingSortCartGroupWrapSecond>
              {/* 시작  */}
              {miniGameBettingInfo.usedOddEven && (
                <MiniGameBettingSortCartGroupWrap>
                  <MiniGameBettingSortCartTitle>홀/짝</MiniGameBettingSortCartTitle>
                  <MiniGameBettingSortCartGroup>
                    <MiniGameBettingSortCartMedium
                      style={{ height: '75px' }}
                      id="KENO_ODD"
                      name={miniGameBettingInfo?.odd || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.odd || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentBlue>홀</MiniGameBettingCartContentBlue>
                      <MiniGameBettingPerSecond>&nbsp;</MiniGameBettingPerSecond>
                    </MiniGameBettingSortCartMedium>
                    <MiniGameBettingSortCartMedium
                      id="KENO_EVEN"
                      name={miniGameBettingInfo?.even || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.even || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentRed>짝</MiniGameBettingCartContentRed>
                    </MiniGameBettingSortCartMedium>
                  </MiniGameBettingSortCartGroup>
                </MiniGameBettingSortCartGroupWrap>
              )}
              {/* 줄 수  */}
              {/* 결과  */}
              {miniGameBettingInfo.usedUnderOver && (
                <MiniGameBettingSortCartGroupWrap>
                  <MiniGameBettingSortCartTitle>언더/오버</MiniGameBettingSortCartTitle>
                  <MiniGameBettingSortCartGroup>
                    <MiniGameBettingSortCartMedium
                      style={{ height: '75px' }}
                      id="KENO_UNDER"
                      name={miniGameBettingInfo?.under || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.under || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentUnder>언더</MiniGameBettingCartContentUnder>
                      <MiniGameBettingPerSecond>4.5</MiniGameBettingPerSecond>
                    </MiniGameBettingSortCartMedium>
                    <MiniGameBettingSortCartMedium
                      id="KENO_OVER"
                      name={miniGameBettingInfo?.over || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.over || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentOver>오버</MiniGameBettingCartContentOver>
                      <MiniGameBettingPerSecond>4.5</MiniGameBettingPerSecond>
                    </MiniGameBettingSortCartMedium>
                  </MiniGameBettingSortCartGroup>
                </MiniGameBettingSortCartGroupWrap>
              )}
            </MiniGameBettingSortCartWrap>
          </MiniGameBettingSortWrap>
          <MiniGameBettingTableWrap>
            <TableBettingInfo>
              <div className="text">{MiniGameTypeEnums.NTRY_SPEED_KENO} 배팅내역</div>
              <div className="icon">
                <FiRefreshCw onClick={fetchSearchMiniGameBettingLog} />
              </div>
            </TableBettingInfo>
            {content.length === 0 ? (
              <NoContentWrap>
                <img src={`${process.env.PUBLIC_URL}/img/main/noslip.svg`} alt="" />
                해당 게임에 배팅내역이 존재하지 않습니다.
              </NoContentWrap>
            ) : (
              <>
                <ReactTableBase
                  columns={miniGameBettingLogData.tableHeaderData}
                  data={rows}
                  tableConfig={tableConfig}
                />
              </>
            )}
          </MiniGameBettingTableWrap>
        </MiniGameContentWrap>
      </CustomerCenterWrap>
    </>
  )
}
export default MobileSpeedKeno

const NoContentWrap = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  color: #fff;
  img {
    width: 50px;
    height: 50px;
  }
`

const InspectOverlay = styled.div`
  position: absolute;
  z-index: 102;
  text-align: center;
  font-size: 12px;
  color: white;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  span {
    position: absolute;
    flex-direction: column;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    /* 아이콘 스타일 */
    position: absolute;
    flex-direction: column;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
  }
`

export const HeaderLogoImg = styled.img`
  width: 270px;
  height: 188px;
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const MiniGameMixedBettingWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 100%;
  img {
    width: 15px;
    height: 15px;
  }
  &:hover {
    cursor: pointer;
    img {
      transform: scale(1.2);
    }
    div {
      transform: scale(1.2);
    }
  }
`

const IframeOverlay = styled.div`
  position: relative;
  color: white;
  justify-content: center;
  width: 100%;
  height: 640px;
  background-color: rgba(255, 255, 255, 0.1);
`

export const TableBettingInfo = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  height: 30px;
  background: #1b1c1f;
  color: #fff;
  font-size: 14px;

  .text {
    flex-grow: 1;
    display: flex;
    justify-content: left;
    padding-left: 20px;
    align-items: center;
  }

  .icon {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-right: 10px; // 오른쪽 여백을 조정하려면 이 값을 조절하세요.
  }
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

const CustomerCenterWrap = styled.div`
  background: #000000;
  height: 100%;
  min-height: 800px;
  padding-top: 5px;
  padding-bottom: 5px;
`
// content Wrap
const MiniGameContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  position: relative;
`

const MiniGameBettingTableWrap = styled.div`
  width: 100%;
  background: #16171a;
  margin: 0 auto;
  display: flex;
  padding-bottom: 5px;
  flex-direction: column;
  gap: 10px;
  z-index: 110;
`

const MiniGameBettingSortWrap = styled.div`
  position: relative;
  display: flex;
  border-radius: 33px;
  border: 4px solid #00000029;
  background-color: rgb(153, 97, 72);
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  overflow: hidden !important;
  margin-bottom: -10px;

  // // 1450 이상
  // @media screen and (min-width: 1450px) {
  //   width: calc(100% - 560px);
  // }

  ${({ isDisabled }) => isDisabled && 'pointer-events: none;'}
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  text-align: center;
  font-size: 12px;
  color: white;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const MiniGameBettingSortHeaderWrap = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 5px solid rgb(244, 225, 192);
  height: 55px;
  padding: 7px 0px;
  align-items: center;
  width: 100%;

  gap: 10px;
`

const MiniGameBettingSortCartWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0px;
  align-items: center;
  width: 100%;
  gap: 20px;
`

const MiniGameBettingSortCartGroupWrapSecond = styled.div`
  display: flex;
  justify-content: center;
  height: 30px;

  padding-bottom: 5px;
  border-bottom: 2px solid #4a4a4a;
  align-items: center;
  text-align: center;
  color: white;
  background-color: #996148;
  width: 97%;
`

const MiniGameBettingSortCartGroupWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 120px;
  padding-bottom: 2px;
  border-bottom: 2px solid #4a4a4a;
  align-items: center;
  width: 97%;
  flex-direction: column;
`

const MiniGameBettingSortCartGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

const MiniGameBettingSortCartTitle = styled.span`
  display: flex;
  justify-content: center;
  height: 27px;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
  background-color: #674836;
  color: #e9d6b5;
  border: 1px solid #4b3528;
`

const MiniGameBettingSortCartMedium = styled.div`
  background-color: #e2c65b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  height: 100%;
  width: 100px;
  align-items: center;
  border: 1px solid #684935;
  background-color: #e2c65b;
  position: relative;
`

const MiniGameBettingSortCartBig = styled.div`
  background-color: #e2c65b;
  display: flex;
  flex-direction: column;
  position: relative;
  height:80px;
  width: 200px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer
  background-color: #e2c65b;
`

const MiniGameBettingPerFirst = styled.div`
  justify-content: center;
  background-color: #4a3427;
  height: 15px;
  font-size: 12px;
  position: absolute;
  top: 0;
  text-align: center;
  align-items: center;
  width: 100%;
  color: white;
`

const MiniGameBettingPer = styled.div`
  justify-content: center;
  background-color: #4a3427;
  height: 20px;
  font-size: 12px;
  // position: absolute;
  padding-top: 2px;
  top: 0;
  text-align: center;
  align-items: center;
  width: 100%;
  color: white;
`

const MiniGameBettingCartContentRed = styled.div`
  cursor: pointer;
  margin-top: 18px;
  margin-bottom: 3px;
  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  font-size: 12px;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentMixedRed = styled.div`
  cursor: pointer;

  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  font-size: 12px;
`
const MiniGameBettingCartContentUnder = styled.div`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 3px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: blue;
  display: flex;
  position: relative;
  border: solid 2px blue;
  font-size: 12px;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentBlue = styled.div`
  margin-top: 18px;
  margin-bottom: 3px;
  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`
const MiniGameBettingCartContentOver = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  border: solid 2px red;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: red;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentMixedBlue = styled.div`
  cursor: pointer;

  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  font-size: 12px;
`

const MiniGameBettingPerSecond = styled.div`
  justify-content: center;
  height: 15px;
  font-size: 12px;
  align-items: center;
  width: 100%;
  color: black;
  position: absolute;
  bottom: 0;
  text-align: center;
`

const MiniGameBettingSortHeaderInfo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
`

const MiniGameBettingSortHeaderLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height 40px;
  color: #f9e5c0;
  background-color: #4a3427;
`
const MiniGameBettingSortHeaderText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height 40px;
  width: 60%;
  color: white;
  background-color: #674836;
`

const MiniGameIframe = styled.iframe`
  width: 300px;
  height: 515px;
`

const MiniGameIframeWrap = styled.div`
  width: 100%;
  height: 530px;
  display: flex;
  background-color: #2f343a;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  border-radius: 33px;
  margin-bottom: -10px;
`
