import renderRadioButtonField from '@/shared/components/form/RadioButton'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FaTrash } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { MdKeyboardArrowDown } from 'react-icons/md'
import parse from 'style-to-object'
import ReactHtmlParser from 'react-html-parser'
import Portal from '../../../shared/components/Portal'
import { searchMemberMoneyDeposit } from '../../../api/memberHistory/memberHistoryApi'
import {
  createUserMoneyDeposit,
  createUserMoneyDepositV3,
  searchDepositRule,
  searchDepositRuleV3,
  searchMinimumAmount,
  updateMemberMoneyHistoryDelete,
} from '../../../api/memberManage/memberManageApi'
import Error from '../../../shared/components/form/Error'
import { FormGroupField } from '../../../shared/components/form/FormElements'
import {
  MobileMoneyFormButtomInfo,
  MoneyFormInput,
  MoneyFormInputWrap,
  MoneyFormModifyButtomInfo,
} from '../../../shared/components/form/newForm'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { BonusStatusEnumsRadioOptions } from '../../../utils/enums/MemberHistory/MemberDepositEnums'
import { HermesMainBorder, HermesMainHover2, HermesMainText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { MoneyDepositValidate } from '../../../utils/validate/moneyFormValidate'
import MoneyDepositListData from '../../MyPage/MoneyDepositHistory/MoneyDepositListData'
import CustomLoading from '../../CustomLoading'
import { CustomSelect } from '../../../shared/components/CustomSelect'
import WorldPayViewPopup from './WorldPayViewPopup'
import { createWorldPayMoneyDeposit, searchWorldPayUrl } from '../../../api/customCenter/customCenterApi'
import CoinModalContent from './CoinModalContent'

const DepositModalContentV2 = () => {
  const [subMenuTitle, setSubMenuTitle] = useState('DEPOSIT')

  const [myInfoLoading, setMyInfoLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })
  /**
   *  회원 입금내역 조회
   */

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberMoneyDeposit = async () => {
    setLoading(true)
    await searchMemberMoneyDeposit(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (subMenuTitle !== 'DEPOSIT_HISTORY') return

    fetchSearchMemberMoneyDeposit()
  }, [historyParams, subMenuTitle])

  const [checkRadio, setCheckRadio] = useState([])
  const [depositListData, setDepositListData] = useState(MoneyDepositListData(content, checkRadio, setCheckRadio))

  useEffect(() => {
    setDepositListData(MoneyDepositListData(content, checkRadio, setCheckRadio))
  }, [content, checkRadio])

  const [rows, setData] = useState(depositListData.tableRowsData)

  useEffect(() => {
    setData(depositListData.tableRowsData)
  }, [depositListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 최소 입금액
  const [minimumDeposit, setMinimumDeposit] = useState('0')
  const [depositMaxAmount, setDepositMaxAmount] = useState('0')

  useEffect(() => {
    setMyInfoLoading(true)

    searchMinimumAmount()
      .then(res => {
        setMinimumDeposit(res.data.minimumDeposit)
        setDepositMaxAmount(res.data.depositMaxAmount)
        setMyInfoLoading(false)
      })
      .catch(error => {
        setMyInfoLoading(false)
      })
  }, [])

  const [depositRuleContent, setDepositRuleContent] = useState('')
  const [isShowDepositRuleContent, setIsShowDepositRuleContent] = useState(false)

  const [depositBonusList, setDepositBonusList] = useState([])
  const [isShowDepositBonusMainSelectDrop, setIsShowDepositBonusMainSelectDrop] = useState(false)

  // 선택한 보너스 타입
  const [selectedBonusType, setSelectedBonusType] = useState(null)

  const onClickDepositBonusTypeHandler = key => {
    const data = depositBonusList.find(item => item.moneyDepositBonusKey === key)

    if (data) {
      setSelectedBonusType({
        moneyDepositBonusKey: data.moneyDepositBonusKey,
        title: data.title,
        bonusType: data.bonusType,
      })
    }
    setIsShowDepositBonusMainSelectDrop(false)
  }

  // V3
  const onClickDepositBonusTypeHandlerV3 = depositBonusItem => {
    setSelectedBonusType({
      moneyDepositBonusKey: depositBonusItem.moneyDepositBonusKey,
      bonusTitle: depositBonusItem.bonusTitle,
      bonusContent: depositBonusItem.bonusContent,
      bonusType: depositBonusItem.bonusType,
    })

    setIsShowDepositBonusMainSelectDrop(false)
  }

  const getDepositBonusImageUrl = type => {
    // hover 때문에 확장자 뺌
    switch (type) {
      case 'BONUS_NONE':
        return `${process.env.PUBLIC_URL}/img/main/bonus_sports_etc`
      case 'BONUS_SPORTS':
      case 'BONUS_SPORTS_NEW':
      case 'BONUS_PLUS':
        return `${process.env.PUBLIC_URL}/img/main/bonus_sports`
      case 'BONUS_CASINO':
      case 'BONUS_CASINO_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_casino`
      case 'BONUS_SLOT_CASINO':
      case 'BONUS_SLOT_CASINO_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_slot`
      case 'BONUS_MINI_GAME':
      case 'BONUS_MINI_GAME_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_mini`
      case 'BONUS_VIRTUAL_GAME':
      case 'BONUS_VIRTUAL_GAME_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_virtual`
      default:
        return ''
    }
  }

  const getSelectedBonusType = () => {
    switch (selectedBonusType?.bonusType) {
      case 'BONUS_NONE':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SPORTS_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SPORTS':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_CASINO':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_CASINO_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SLOT_CASINO':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SLOT_CASINO_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_PLUS':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_MINI_GAME':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_MINI_GAME_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_HOLDEM':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_HOLDEM_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_VIRTUAL_GAME':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_VIRTUAL_GAME_NEW':
        return <span>{selectedBonusType?.title}</span>
      default:
        return <span>입금 보너스 선택하기</span>
    }
  }

  const fetchSearchDepositRule = async () => {
    setLoading(true)
    await searchDepositRule(historyParams)
      .then(response => {
        setDepositRuleContent(response.data.depositRule)
        setDepositBonusList(response.data.depositBonusList)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const fetchSearchDepositRuleV3 = async () => {
    setLoading(true)
    await searchDepositRuleV3(historyParams)
      .then(response => {
        setDepositRuleContent(response.data.depositRule)
        setDepositBonusList(response.data.depositBonusList)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (subMenuTitle === 'DEPOSIT') {
      fetchSearchDepositRule()
    }

    if (subMenuTitle === 'DEPOSIT_V3') {
      fetchSearchDepositRuleV3()
    }
  }, [subMenuTitle])

  // 신청금액
  const [applyMoney, setApplyMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  const [casinoGameUrl, setCasinoGameUrl] = useState('')
  const [casinoGameViewPopupShow, setCasinoGameViewPopupShow] = useState(false)
  const onCloseCasinoGameViewPopupShowHandler = () => {
    setCasinoGameViewPopupShow(false)
  }

  const onSubmit2 = e => {
    if (apiFlag) return
    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (!selectedBonusType) {
      alert('입금보너스를 선택해주세요.')
      return
    }

    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) > Number(depositMaxAmount)) {
      alert(`최대 ${depositMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 입금 가능합니다.`)
      return
    }

    setApiFlag(true)

    const body = {
      moneyType: 'DEPOSIT',
      applyMoney: checkMoney,
      bonusType: selectedBonusType?.bonusType || 'BONUS_NONE',
      bonusTypeKr: selectedBonusType?.title || '보너스를 선택하지 않음',
    }

    setLoading(true)
    createWorldPayMoneyDeposit(body)
      .then(res => {
        setLoading(false)
        alert('입금신청이 완료되었습니다.\n새로운 창에서 나오는 계좌에서 입금 진행 부탁드립니다.')
        setApiFlag(false)
        searchWorldPayUrl()
          .then(res2 => {
            const iframeUrl = res2.data.url

            if (iframeUrl) {
              setCasinoGameUrl(iframeUrl)
              setCasinoGameViewPopupShow(true)
            }
          })
          .catch(error => {
            const { errorCode, message } = error.response.data

            alert('다시 시도해주세요.')
          })
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        setLoading(false)
        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1010':
            alert('입금이 불가능한 시간입니다.')
            break
          case 'ASSET-1004':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('입금신청이 실패했습니다. 다시 시도해주세요.')
        }

        setApiFlag(false)
        fetchSearchDepositRule()
        fetchSearchMemberMoneyDeposit()
      })
  }

  const onSubmit = e => {
    if (apiFlag) return
    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (!selectedBonusType) {
      alert('입금보너스를 선택해주세요.')
      return
    }

    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) > Number(depositMaxAmount)) {
      alert(`최대 ${depositMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 입금 가능합니다.`)
      return
    }

    setApiFlag(true)

    const body = {
      moneyType: 'DEPOSIT',
      applyMoney: checkMoney,
      bonusType: selectedBonusType?.bonusType || 'BONUS_NONE',
      bonusTypeKr: selectedBonusType?.title || '보너스를 선택하지 않음',
    }

    setLoading(true)
    createUserMoneyDeposit(body)
      .then(res => {
        setLoading(false)
        alert('입금신청이 완료되었습니다.')
        setApiFlag(false)
        fetchSearchMemberMoneyDeposit()
        fetchSearchDepositRule()
        onClearClickHandler()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        setLoading(false)
        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1010':
            alert('입금이 불가능한 시간입니다.')
            break
          case 'ASSET-1004':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('입금신청이 실패했습니다. 다시 시도해주세요.')
        }

        setApiFlag(false)
        fetchSearchDepositRule()
        fetchSearchMemberMoneyDeposit()
      })
  }

  const onSubmitV3 = e => {
    if (apiFlag) return

    const checkMoney = applyMoney?.toString().replaceAll(',', '')

    // if (!selectedBonusType) {
    //   alert('입금보너스를 선택해주세요.')
    //   return
    // }

    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) > Number(depositMaxAmount)) {
      alert(`최대 ${depositMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 입금 가능합니다.`)
      return
    }

    setApiFlag(true)

    const body = {
      moneyType: 'DEPOSIT',
      applyMoney: checkMoney,
      moneyDepositBonusKey: selectedBonusType?.moneyDepositBonusKey,
      bonusTitle: selectedBonusType?.bonusTitle || '',
      bonusContent: selectedBonusType?.bonusContent || '',
    }

    setLoading(true)
    createUserMoneyDepositV3(body)
      .then(res => {
        alert('입금신청이 완료되었습니다.')
        onClearClickHandler()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1010':
            alert('입금이 불가능한 시간입니다.')
            break
          case 'ASSET-1004':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('입금신청이 실패했습니다. 다시 시도해주세요.')
        }
      })
      .finally(() => {
        setLoading(false)
        setApiFlag(false)
        fetchSearchDepositRuleV3()
        fetchSearchMemberMoneyDeposit()
      })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 목록을 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'DEPOSIT',
        moneyHistoryKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyDeposit()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제 실패했습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyDeposit()
        })
    }
  }
  const [coinModalShow, setCoinModalShow] = useState(false)
  const [coinModalVisible, setCoinModalVisble] = useState(false)
  const onClickCoinModalHandler = () => {
    setCoinModalShow(true)
  }

  const onCloseCoinModalHandler = () => {
    setCoinModalShow(false)
  }

  return (
    <ContentWrap>
      {casinoGameViewPopupShow && (
        <WorldPayViewPopup
          casinoGameUrl={casinoGameUrl}
          onCloseCasinoGameViewPopupShowHandler={onCloseCasinoGameViewPopupShowHandler}
        />
      )}
      {coinModalShow && (
        <Portal elementId="signup-modal">
          <SignUpModalOverlay visible={coinModalShow} />
          <SignUpModalWrapper
            onClick={onCloseCoinModalHandler}
            tabIndex="-1"
            visible={coinModalShow}
            className="sidebar-container"
          >
            <SignUpModalInner tabIndex="0">
              <SignUpModalHeader>
                <SignUpModalCloseButton
                  onClick={onCloseCoinModalHandler}
                  src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                  alt=""
                />
              </SignUpModalHeader>
              <SignUpModalContentWrap>
                <CoinModalContent />
              </SignUpModalContentWrap>
            </SignUpModalInner>
          </SignUpModalWrapper>
        </Portal>
      )}
      <ContentInnerScrollWrap>
        <ContentInnerWrap className="sidebar-container">
          <SubMenuItemListWrap>
            <div>
              <SubMenuItemBox active={subMenuTitle === 'DEPOSIT'} onClick={() => setSubMenuTitle('DEPOSIT')}>
                입금
              </SubMenuItemBox>
              <SubMenuItemBox active={subMenuTitle === 'DEPOSIT_V3'} onClick={() => setSubMenuTitle('DEPOSIT_V3')}>
                입금V2
              </SubMenuItemBox>
              <SubMenuItemBox
                active={subMenuTitle === 'DEPOSIT_HISTORY'}
                onClick={() => setSubMenuTitle('DEPOSIT_HISTORY')}
              >
                입금내역
              </SubMenuItemBox>
            </div>
          </SubMenuItemListWrap>
          {apiFlag && <CustomLoading />}
          <ContentBox>
            {
              {
                DEPOSIT: (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '58%' }}>
                      <MoneyDepositInfoWrap>
                        <MoneyDepositInfoLeftWrap>
                          <MoneyDepositInfoLeftBox>
                            <MoneyDepositInfoLeftTextBox>
                              <div>스포츠캐쉬</div>
                              <MoneyDepositInfoLeftText>
                                {holdingMoney?.toString().replace(commonReg2, ',')} 원
                              </MoneyDepositInfoLeftText>
                            </MoneyDepositInfoLeftTextBox>
                          </MoneyDepositInfoLeftBox>
                        </MoneyDepositInfoLeftWrap>
                        <MoneyDepositInfoRightWrap>
                          <MoneyDepositInfoRightBox>
                            <MoneyDepositInfoRightTextBox>
                              <div>카지노캐쉬</div>
                              <MoneyDepositInfoRightText>
                                {totalGameMoney?.toString().replace(commonReg2, ',')} 원
                              </MoneyDepositInfoRightText>
                            </MoneyDepositInfoRightTextBox>
                          </MoneyDepositInfoRightBox>
                        </MoneyDepositInfoRightWrap>
                      </MoneyDepositInfoWrap>
                      <Form
                        onSubmit={onSubmit}
                        initialValues={{
                          memberId: userId,
                          memberName: userName,
                          bankName,
                          accountHolder: accountNum,
                          applyMoney,
                          holdingMoney,
                          minimumDeposit,
                          depositMaxAmount,
                        }}
                        validate={MoneyDepositValidate}
                      >
                        {({ handleSubmit, form: { reset } }) => (
                          <div onSubmit={handleSubmit}>
                            <ContentRowSecondWrap>
                              <div
                                style={{
                                  fontWeight: '700',
                                  display: 'block',
                                  width: '100%',
                                  padding: '0 0 24px 24px',
                                  fontSize: '12px',
                                }}
                              >
                                ※ <span style={{ color: `${HermesMainText}` }}>계좌문의</span>는 문의란 계좌문의
                                클릭하시면 관리자에게 신청됩니다.
                              </div>
                              <DepositBonusSelectWrap>
                                <DepositBonusSelectInnerWrap>
                                  <DepositBonusMainSelectWrap
                                    onClick={() => {
                                      setIsShowDepositBonusMainSelectDrop(prev => !prev)
                                    }}
                                  >
                                    {getSelectedBonusType()}
                                    <CustomMdKeyboardArrowDown />
                                  </DepositBonusMainSelectWrap>
                                  {isShowDepositBonusMainSelectDrop && (
                                    <DepositBonusMainSelectDropWrap>
                                      {/* 아래부터 반복 */}
                                      {depositBonusList.map(item => (
                                        <DepositBonusMainSelectDropItemWrap>
                                          <DepositBonusMainSelectDropItemBox
                                            url={getDepositBonusImageUrl(item.bonusType)}
                                            onClick={() => {
                                              onClickDepositBonusTypeHandler(item.moneyDepositBonusKey)
                                            }}
                                          >
                                            <DepositBonusMainSelectDropItemImg />
                                            <DepositBonusMainSelectDropItemTextBox sc={item.content}>
                                              <DepositBonusMainSelectDropItemTextContent>
                                                {item.title}
                                              </DepositBonusMainSelectDropItemTextContent>
                                              {item.content && (
                                                <DepositBonusMainSelectDropItemTextSubContent>
                                                  {item.content}
                                                </DepositBonusMainSelectDropItemTextSubContent>
                                              )}
                                            </DepositBonusMainSelectDropItemTextBox>
                                          </DepositBonusMainSelectDropItemBox>
                                        </DepositBonusMainSelectDropItemWrap>
                                      ))}
                                    </DepositBonusMainSelectDropWrap>
                                  )}
                                </DepositBonusSelectInnerWrap>
                              </DepositBonusSelectWrap>
                              <div style={{ marginTop: '16px', width: '100%' }}>
                                <p>
                                  * 원하시는 이벤트종목 참여 후 다른게임 이용시 불이익을 받을 수 있습니다.
                                  <span style={{ color: `${HermesMainText}` }}> (몰수 또는 이벤트 참여 제제)</span>
                                </p>
                                <p>
                                  * 이벤트 참여 전 필히{' '}
                                  <span style={{ color: `${HermesMainText}` }}>이벤트 이용규정</span> 확인 부탁드립니다.
                                </p>
                                <p>* 당일 환전내역 있을시 이벤트 참여 불가능 하십니다.</p>
                                <p style={{ color: 'red' }}>* 반드시 입금 후 입금 신청 부탁드립니다.</p>
                              </div>
                              <CustomerCenterFormGroup style={{ marginBottom: '30px' }}>
                                <CustomerCenterFormLabel>
                                  입금신청 금액
                                  <div style={{ marginTop: '5px', width: '100%', fontSize: '12px', fontWeight: '400' }}>
                                    <p>
                                      * 입금 최소액은{' '}
                                      <span style={{ color: `${HermesMainText}` }}>
                                        {' '}
                                        {minimumDeposit?.toString().replace(commonReg2, ',') || 0}원
                                      </span>
                                      , 1회 입금 최대액
                                      <span style={{ color: `${HermesMainText}` }}> (레벨별 상이)</span>은{' '}
                                      <span style={{ color: `${HermesMainText}` }}>
                                        {' '}
                                        {depositMaxAmount?.toString().replace(commonReg2, ',') || 0}원
                                      </span>{' '}
                                      입니다.
                                    </p>
                                  </div>
                                </CustomerCenterFormLabel>
                                <CustomCenterFormField>
                                  <Field name="applyMoney">
                                    {({ input, meta }) => (
                                      <MoneyFormInputWrap>
                                        <MoneyFormInput
                                          {...input}
                                          type="text"
                                          value={input.value}
                                          onChange={e => {
                                            input.onChange(e)
                                            setApplyMoney(e.target.value)
                                          }}
                                        />
                                        {meta.error && <Error error={meta.error} />}
                                      </MoneyFormInputWrap>
                                    )}
                                  </Field>
                                </CustomCenterFormField>
                              </CustomerCenterFormGroup>
                              <CustomerCenterFormGroup>
                                <CustomCenterFormField>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="10000"
                                    onClick={e => {
                                      onClickMoneyHandler('10000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>1만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="50000"
                                    onClick={e => {
                                      onClickMoneyHandler('50000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>5만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="100000"
                                    onClick={e => {
                                      onClickMoneyHandler('100000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>10만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="500000"
                                    onClick={e => {
                                      onClickMoneyHandler('500000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>50만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="1000000"
                                    onClick={e => {
                                      onClickMoneyHandler('1000000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>100만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="3000000"
                                    onClick={e => {
                                      onClickMoneyHandler('3000000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>300만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="10000000"
                                    onClick={e => {
                                      onClickMoneyHandler('10000000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>1000만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="0"
                                    onClick={e => {
                                      onClearClickHandler()
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>
                                      <FaTrash />
                                    </MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                </CustomCenterFormField>
                              </CustomerCenterFormGroup>
                              <div style={{ width: '100%', display: 'flex', gap: '10px' }}>
                                <MoneyButton variant="primary" type="submit" onClick={onSubmit}>
                                  <CustomMoneyFormModifyButtomInfo>원화 입금신청</CustomMoneyFormModifyButtomInfo>
                                </MoneyButton>
                                <MoneyButton
                                  style={{ background: 'darkorange', border: 'solid 1px darkorange' }}
                                  variant="primary"
                                  type="submit"
                                  onClick={onClickCoinModalHandler}
                                >
                                  <CustomMoneyFormModifyButtomInfo>코인(USDT) 입금신청</CustomMoneyFormModifyButtomInfo>
                                </MoneyButton>
                              </div>
                            </ContentRowSecondWrap>
                          </div>
                        )}
                      </Form>
                    </div>
                    <div style={{ width: '42%' }}>
                      <MoneyDepositRuleWrap>
                        {isShowDepositRuleContent ? (
                          <MoneyDepositRuleContentWrap>
                            {ReactHtmlParser(depositRuleContent, { transform: transformFn })}
                          </MoneyDepositRuleContentWrap>
                        ) : (
                          <>
                            <MoneyDepositRuleImgWrap>
                              <img src={`${process.env.PUBLIC_URL}/img/main/iframeLogo.png`} alt="" />
                            </MoneyDepositRuleImgWrap>
                            <MoneyDepositRuleInfo1 onClick={onSubmit2}>
                              사용하기 편리하고, 신속한 입금&출금
                            </MoneyDepositRuleInfo1>
                            <MoneyDepositRuleInfo2>
                              <span
                                onClick={() => {
                                  setIsShowDepositRuleContent(true)
                                }}
                              >
                                입금규정 확인하기
                              </span>
                            </MoneyDepositRuleInfo2>
                          </>
                        )}
                      </MoneyDepositRuleWrap>
                    </div>
                  </div>
                ),
                DEPOSIT_V3: (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '58%' }}>
                      <MoneyDepositInfoWrap>
                        <MoneyDepositInfoLeftWrap>
                          <MoneyDepositInfoLeftBox>
                            <MoneyDepositInfoLeftTextBox>
                              <div>스포츠캐쉬</div>
                              <MoneyDepositInfoLeftText>
                                {holdingMoney?.toString().replace(commonReg2, ',')} 원
                              </MoneyDepositInfoLeftText>
                            </MoneyDepositInfoLeftTextBox>
                          </MoneyDepositInfoLeftBox>
                        </MoneyDepositInfoLeftWrap>
                        <MoneyDepositInfoRightWrap>
                          <MoneyDepositInfoRightBox>
                            <MoneyDepositInfoRightTextBox>
                              <div>카지노캐쉬</div>
                              <MoneyDepositInfoRightText>
                                {totalGameMoney?.toString().replace(commonReg2, ',')} 원
                              </MoneyDepositInfoRightText>
                            </MoneyDepositInfoRightTextBox>
                          </MoneyDepositInfoRightBox>
                        </MoneyDepositInfoRightWrap>
                      </MoneyDepositInfoWrap>
                      <Form
                        onSubmit={onSubmitV3}
                        initialValues={{
                          memberId: userId,
                          memberName: userName,
                          bankName,
                          accountHolder: accountNum,
                          applyMoney,
                          holdingMoney,
                          minimumDeposit,
                          depositMaxAmount,
                        }}
                        validate={MoneyDepositValidate}
                      >
                        {({ handleSubmit, form: { reset } }) => (
                          <div onSubmit={handleSubmit}>
                            <ContentRowSecondWrap>
                              <div
                                style={{
                                  fontWeight: '700',
                                  display: 'block',
                                  width: '100%',
                                  padding: '0 0 24px 24px',
                                  fontSize: '12px',
                                }}
                              >
                                ※ <span style={{ color: `${HermesMainText}` }}>계좌문의</span>는 문의란 계좌문의
                                클릭하시면 관리자에게 신청됩니다.
                              </div>
                              <DepositBonusSelectWrap>
                                <DepositBonusSelectInnerWrap>
                                  <DepositBonusMainSelectWrap
                                    onClick={() => {
                                      setIsShowDepositBonusMainSelectDrop(prev => !prev)
                                    }}
                                  >
                                    {selectedBonusType?.bonusTitle || '입금 보너스 선택하기'}
                                    <CustomMdKeyboardArrowDown />
                                  </DepositBonusMainSelectWrap>
                                  {isShowDepositBonusMainSelectDrop && (
                                    <DepositBonusMainSelectDropWrap>
                                      {/* 아래부터 반복 */}
                                      {depositBonusList.map(item => (
                                        <DepositBonusMainSelectDropItemWrap>
                                          <DepositBonusMainSelectDropItemBox
                                            url={getDepositBonusImageUrl(item.bonusType)}
                                            onClick={() => {
                                              onClickDepositBonusTypeHandlerV3(item)
                                            }}
                                          >
                                            <DepositBonusMainSelectDropItemImg />
                                            <DepositBonusMainSelectDropItemTextBox sc={item.bonusContent}>
                                              <DepositBonusMainSelectDropItemTextContent>
                                                {item.bonusTitle}
                                              </DepositBonusMainSelectDropItemTextContent>
                                              <DepositBonusMainSelectDropItemTextSubContent>
                                                {item.bonusContent}
                                              </DepositBonusMainSelectDropItemTextSubContent>
                                            </DepositBonusMainSelectDropItemTextBox>
                                          </DepositBonusMainSelectDropItemBox>
                                        </DepositBonusMainSelectDropItemWrap>
                                      ))}
                                    </DepositBonusMainSelectDropWrap>
                                  )}
                                </DepositBonusSelectInnerWrap>
                              </DepositBonusSelectWrap>

                              <div style={{ marginTop: '16px', width: '100%' }}>
                                <p>
                                  * 원하시는 이벤트종목 참여 후 다른게임 이용시 불이익을 받을 수 있습니다.
                                  <span style={{ color: `${HermesMainText}` }}> (몰수 또는 이벤트 참여 제제)</span>
                                </p>
                                <p>
                                  * 이벤트 참여 전 필히{' '}
                                  <span style={{ color: `${HermesMainText}` }}>이벤트 이용규정</span> 확인 부탁드립니다.
                                </p>
                                <p>* 당일 환전내역 있을시 이벤트 참여 불가능 하십니다.</p>
                                <p style={{ color: 'red' }}>* 반드시 입금 후 입금 신청 부탁드립니다.</p>
                              </div>
                              <CustomerCenterFormGroup style={{ marginBottom: '30px' }}>
                                <CustomerCenterFormLabel>
                                  입금신청 금액
                                  <div style={{ marginTop: '5px', width: '100%', fontSize: '12px', fontWeight: '400' }}>
                                    <p>
                                      * 입금 최소액은{' '}
                                      <span style={{ color: `${HermesMainText}` }}>
                                        {' '}
                                        {minimumDeposit?.toString().replace(commonReg2, ',') || 0}원
                                      </span>
                                      , 1회 입금 최대액
                                      <span style={{ color: `${HermesMainText}` }}> (레벨별 상이)</span>은{' '}
                                      <span style={{ color: `${HermesMainText}` }}>
                                        {' '}
                                        {depositMaxAmount?.toString().replace(commonReg2, ',') || 0}원
                                      </span>{' '}
                                      입니다.
                                    </p>
                                  </div>
                                </CustomerCenterFormLabel>
                                <CustomCenterFormField>
                                  <Field name="applyMoney">
                                    {({ input, meta }) => (
                                      <MoneyFormInputWrap>
                                        <MoneyFormInput
                                          {...input}
                                          type="text"
                                          value={input.value}
                                          onChange={e => {
                                            input.onChange(e)
                                            setApplyMoney(e.target.value)
                                          }}
                                        />
                                        {meta.error && <Error error={meta.error} />}
                                      </MoneyFormInputWrap>
                                    )}
                                  </Field>
                                </CustomCenterFormField>
                              </CustomerCenterFormGroup>
                              <CustomerCenterFormGroup>
                                <CustomCenterFormField>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="10000"
                                    onClick={e => {
                                      onClickMoneyHandler('10000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>1만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="50000"
                                    onClick={e => {
                                      onClickMoneyHandler('50000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>5만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="100000"
                                    onClick={e => {
                                      onClickMoneyHandler('100000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>10만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="500000"
                                    onClick={e => {
                                      onClickMoneyHandler('500000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>50만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="1000000"
                                    onClick={e => {
                                      onClickMoneyHandler('1000000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>100만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="3000000"
                                    onClick={e => {
                                      onClickMoneyHandler('3000000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>300만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="10000000"
                                    onClick={e => {
                                      onClickMoneyHandler('10000000')
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>1000만원</MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                  <MoneyFormButton
                                    size="sm"
                                    type="button"
                                    variant="outline-secondary"
                                    name="0"
                                    onClick={e => {
                                      onClearClickHandler()
                                    }}
                                  >
                                    <MobileMoneyFormButtomInfo>
                                      <FaTrash />
                                    </MobileMoneyFormButtomInfo>
                                  </MoneyFormButton>
                                </CustomCenterFormField>
                              </CustomerCenterFormGroup>
                              <MoneyButton variant="primary" type="submit" onClick={onSubmitV3}>
                                <CustomMoneyFormModifyButtomInfo>입금신청</CustomMoneyFormModifyButtomInfo>
                              </MoneyButton>
                            </ContentRowSecondWrap>
                          </div>
                        )}
                      </Form>
                    </div>
                    <div style={{ width: '42%' }}>
                      <MoneyDepositRuleWrap>
                        {isShowDepositRuleContent ? (
                          <MoneyDepositRuleContentWrap>
                            {ReactHtmlParser(depositRuleContent, { transform: transformFn })}
                          </MoneyDepositRuleContentWrap>
                        ) : (
                          <>
                            <MoneyDepositRuleImgWrap>
                              <img src={`${process.env.PUBLIC_URL}/img/main/iframeLogo.png`} alt="" />
                            </MoneyDepositRuleImgWrap>
                            <MoneyDepositRuleInfo1 onClick={onSubmit2}>
                              사용하기 편리하고, 신속한 입금&출금
                            </MoneyDepositRuleInfo1>
                            <MoneyDepositRuleInfo2>
                              <span
                                onClick={() => {
                                  setIsShowDepositRuleContent(true)
                                }}
                              >
                                입금규정 확인하기
                              </span>
                            </MoneyDepositRuleInfo2>
                          </>
                        )}
                      </MoneyDepositRuleWrap>
                    </div>
                  </div>
                ),
                DEPOSIT_HISTORY: (
                  <>
                    {loading ? (
                      <CustomLoading />
                    ) : (
                      <>
                        <MoneyDepositHeaderTwoWrap>
                          <MoneyDepositHeaderThirdInfoBox>
                            <MoneyDepositHeaderThirdInfo>입금 내역</MoneyDepositHeaderThirdInfo>
                          </MoneyDepositHeaderThirdInfoBox>
                          <ListWrap>
                            <CustomDeleteButton
                              onClick={() => {
                                setCheckRadio(content.map(value => value.moneyDepositKey))
                              }}
                            >
                              <CustomButtonInfos>전체선택</CustomButtonInfos>
                            </CustomDeleteButton>
                            <CustomDeleteButton
                              onClick={() => {
                                onDeleteHandler()
                              }}
                            >
                              <CustomButtonInfos>삭제하기</CustomButtonInfos>
                            </CustomDeleteButton>
                          </ListWrap>
                        </MoneyDepositHeaderTwoWrap>
                        <MoneyDepositBodyTwoWrap>
                          <ReactTableBase
                            columns={depositListData.tableHeaderData}
                            data={rows}
                            tableConfig={tableConfig}
                          />
                        </MoneyDepositBodyTwoWrap>
                      </>
                    )}
                  </>
                ),
              }[subMenuTitle]
            }
          </ContentBox>
        </ContentInnerWrap>
      </ContentInnerScrollWrap>
    </ContentWrap>
  )
}

export default DepositModalContentV2

const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: 120px;
  padding: 0px 8px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    color: grey;
  }
`

const CustomButtonInfos = styled(MoneyFormModifyButtomInfo)`
  font-size: 13px;
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  font-family: 'Malgun Gothic', dotum;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  height: calc(100vh - 270px);
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  ${props =>
    props.active &&
    `
    border-bottom: 1px solid ${HermesMainBorder};
    `}
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`

const MoneyDepositInfoWrap = styled.div`
  font-weight: 700;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  box-sizing: border-box;
  display: flex;
  font-family: 'Malgun Gothic', dotum;
`

const MoneyDepositInfoLeftWrap = styled.div`
  background: rgb(18, 18, 20);
  width: calc(50% - 8px);
  float: left;
  display: block;
`
const MoneyDepositInfoLeftBox = styled.div`
  padding: 16px 24px;
  border-radius: 6px;
`

const MoneyDepositInfoLeftTextBox = styled.div`
  float: left;
  padding-left: 24px;
  font-weight: 700;
`
const MoneyDepositInfoLeftText = styled.div`
  font-size: 14px;
  color: ${HermesMainHover2};
`

const MoneyDepositInfoRightWrap = styled.div`
  background: rgb(18, 18, 20);
  width: calc(50% - 8px);
  float: right;
  display: block;
`
const MoneyDepositInfoRightBox = styled.div`
  padding: 16px 24px;
  border-radius: 6px;
`

const MoneyDepositInfoRightTextBox = styled.div`
  float: right;
  text-align: right;
  padding-right: 24px;
`
const MoneyDepositInfoRightText = styled.div`
  font-size: 14px;
  color: ${HermesMainHover2};
`

const CustomFormGroupWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
`

const CustomFormGroupField = styled(FormGroupField)``

const MoneyDepositHeaderThirdInfoBox = styled.div`
  float: left;
  padding-top: 10px;
  height: 40px;
  display: block;
  width: 100%;
`

const MoneyDepositHeaderThirdInfo = styled.span`
  color: #fff;
  font-size: 14px;
`

const MoneyDepositHeaderTwoWrap = styled.div`
  padding: 8px 8px 8px 16px;
  border-radius: 6px;
  background: #171719;
  font-weight: 900;
  display: flex;
`

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 20px 0;

  gap: 10px;

  .react-table__column-header {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
  }
  tbody tr td {
    font-family: 'Malgun Gothic', dotum;
    padding: 16px 5px;
    font-size: 14px;
    font-weight: 700;
  }
`

const ContentRowSecondWrap = styled.div`
  margin-top: 30px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  span {
    color: #fff;
  }
`

const ContentRowSelectWrap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  background: #202024 !important;
  padding: 10px 20px;
  width: 100%;
`

const CustomerCenterFormGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const MoneyButton = styled.button`
  width: 100%;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

const CustomMoneyFormModifyButtomInfo = styled(MoneyFormModifyButtomInfo)`
  color: #000 !important;
  font-weight: 700;
  font-size: 14px;
`

const CustomCenterFormField = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`

const CustomerCenterFormLabel = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
  text-align: left;
  font-size: 22px;
  font-family: 'Malgun Gothic', dotum;
`

const MoneyFormButton = styled.button`
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 2px;
  background: #343438;
`

const MoneyDepositRuleWrap = styled.div`
  background: #141517 !important;
  padding: 24px;
  box-sizing: border-box;
  display: block;
  font-family: 'Malgun Gothic', dotum;
  color: rgb(255, 255, 255);
  font-size: 13px;
  height: 100%;
`

const MoneyDepositRuleImgWrap = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 160px;
    height: auto;
    margin-top: 80px;
  }
`

const MoneyDepositRuleInfo1 = styled.div`
  color: #878e99;
  word-break: keep-all;
  text-align: center;
  font-size: 14px;
  margin-top: 15px;
  display: block;
  position: relative;
`

const MoneyDepositRuleInfo2 = styled.div`
  text-align: center;
  margin-top: 30px;
  box-sizing: border-box;
  display: block;
  position: relative;

  span {
    border-radius: 5px;
    color: ${HermesMainText};
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    padding: 10px 20px 10px 20px;
    text-align: center;
  }
`

const MoneyDepositRuleContentWrap = styled.div`
  word-break: keep-all;
`

const DepositBonusSelectWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: block;
  color: #fff;
  font-family: 'Malgun Gothic', dotum;
  font-size: 16px;
  word-break: break-all;
`

const DepositBonusSelectInnerWrap = styled.div`
  width: 100%;
  float: left;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectWrap = styled.div`
  width: 100%;
  background: #19191c;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
  padding: 12px;
  box-sizing: border-box;
  display: block;

  span {
    cursor: pointer;
  }
`

const CustomMdKeyboardArrowDown = styled(MdKeyboardArrowDown)`
  color: #fff;
  width: auto;
  height: 25px;
  float: right;
  cursor: pointer;
`

const DepositBonusMainSelectDropWrap = styled.div`
  background: #19191c;
  position: absolute;
  width: 100%;
  z-index: 1000;
  font-size: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectDropItemWrap = styled.div`
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  display: block;
`

const DepositBonusMainSelectDropItemImg = styled.div`
  background-position: 50%;
  background-size: 100% 100%;
  width: 52px;
  height: 52px;
  float: left;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectDropItemBox = styled.div`
  background-color: #121212;
  padding: 10px;
  border-radius: 3px;
  display: inline-block;
  width: 100%;
  cursor: pointer;

  ${DepositBonusMainSelectDropItemImg} {
    background-image: ${props => props.url && `url(${props.url}.png)`};
  }

  &:hover {
    background-color: ${HermesMainBorder};

    ${DepositBonusMainSelectDropItemImg} {
      background-image: ${props => props.url && `url(${props.url}_hover.png)`};
    }
  }
`

const DepositBonusMainSelectDropItemTextBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.sc ? 'column' : 'row')};
  float: left;
  padding-left: 15px;
  height: 52px;
  box-sizing: border-box;
`

const DepositBonusMainSelectDropItemTextContent = styled.div`
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  display: block;
`
const DepositBonusMainSelectDropItemTextSubContent = styled.div`
  width: 100%;
  text-align: left;
  font-size: 13px;
  margin-top: 5px;
  box-sizing: border-box;
  position: relative;
  display: block;

  color: ${HermesMainText};
`

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9990;
  overflow: auto;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9990;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #000000;
  width: 800px;
  margin: 0 auto;
  // min-height: 250px;
  margin-top: 10vh;
  color: #606266;
  word-break: break-all;

  height: calc(100vh - 150px);
`

const SignUpModalHeader = styled.div`
  height: 60px;
  background: #16171a !important;
  display: flex;
  justify-content: center;
  align-items: center;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  color: #606266;
  word-break: break-all;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #16171a !important;
`
