import { all, call, put, takeLatest } from 'redux-saga/effects'
import { searchUserHoldingAsset } from '../../api/memberManage/memberManageApi'
import { SocketIOEventName } from '../../utils/enums/WebSocket/SocketIOEventName'
import { SocketIORequestMessageType } from '../../utils/enums/WebSocket/SocketIORequestMessageType'
import { emitPromise, socket } from '../../utils/socket'
import {
  createUserLogAction,
  fetchMemberInfoAction,
  initHomeInfoAction,
  initPopupInfoAction,
  initSportsInfoAction,
  initVirtualInfoAction,
  setHomeInit,
  setPopupInitData,
  setUserInfo,
} from '../memberInfoSlice'
import { setSportLiveMarketCombination, setSportMarketCombination, setSportsBettingInfos } from '../sportsBettingSlice'
import { setDomesticPreMatchCategory } from '../sportsDomesticInfoSlice'
import { setLiveInPlayCategory } from '../sportsLiveInfoSlice'
import { setOverseasPreMatchCategory } from '../sportsOverseasInfoSlice'
import { setSpecialPreMatchCategory } from '../sportsSpecialInfoSlice'
import { setVirtualGameBettingInfos } from '../virtualGameBettingSlice'
import { setVirtualInitInfo } from '../virtualGameInfoSlice'

function* handleFetchMemberInfo(action) {
  try {
    const res = yield call(searchUserHoldingAsset, action.payload)

    yield put(setUserInfo(res.data))
    yield put(setSportsBettingInfos(res.data.sportsBettingInfos))
    yield put(setVirtualGameBettingInfos(res.data.user.virtualGameBettingInfos))
  } catch (error) {
    // console.log();
  }
}

function* watchFetchMemberInfo() {
  yield takeLatest(fetchMemberInfoAction, handleFetchMemberInfo)
}

// 홈 init

function* handleInitHomeInfo(action) {
  try {
    const payload = {
      type: SocketIORequestMessageType.HOME_INIT,
      payload: {
        // eslint-disable-next-line no-useless-escape
        host: window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1'),
      },
    }

    const res = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (res.status) {
      yield put(
        setHomeInit({
          realTimeDepositList: res.data.realTimeDepositList,
          realTimeWithdrawalList: res.data.realTimeWithdrawalList,
          domesticSportsUsing: res.data.domesticSportsUsing,
          domesticSportsName: res.data.domesticSportsName,
          overseasSportsUsing: res.data.overseasSportsUsing,
          overseasSportsName: res.data.overseasSportsName,
          specialSportsUsing: res.data.specialSportsUsing,
          specialSportsName: res.data.specialSportsName,
          liveSportsUsing: res.data.liveSportsUsing,
          liveSportsName: res.data.liveSportsName,
          revolutionHoldemInfo: res.data.revolutionHoldemInfo,
          wildHoldemInfo: res.data.wildHoldemInfo,
          attendanceUse: res.data.attendanceUse,
        }),
      )
    }
  } catch (error) {
    // console.log();
  }
}

function* watchInitHomeInfo() {
  yield takeLatest(initHomeInfoAction, handleInitHomeInfo)
}

// 팝업 init

function* handleInitPopupInfo(action) {
  try {
    const payload = {
      type: SocketIORequestMessageType.POPUP_INIT,
      payload: {
        // eslint-disable-next-line no-useless-escape
        host: window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1'),
      },
    }

    const res = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (res.status) {
      yield put(setPopupInitData(res.data))
    }
  } catch (error) {
    // console.log();
  }
}

function* watchInitPopupInfo() {
  yield takeLatest(initPopupInfoAction, handleInitPopupInfo)
}

// 스포츠 init
function* handleInitSportInfo(action) {
  try {
    const payload = {
      type: SocketIORequestMessageType.SPORT_INIT,
      payload: {
        // eslint-disable-next-line no-useless-escape
        host: window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1'),
      },
    }

    const res = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (res.status) {
      yield put(setDomesticPreMatchCategory(res.data.domesticPreMatchCategory))
      yield put(setOverseasPreMatchCategory(res.data.overseasPreMatchCategory))
      yield put(setSpecialPreMatchCategory(res.data.specialPreMatchCategory))
      yield put(setLiveInPlayCategory(res.data.liveCategory))
      yield put(setSportMarketCombination(res.data.marketCombinationList))
      yield put(setSportLiveMarketCombination(res.data.liveMarketCombinationList))
    }
  } catch (error) {
    // console.log();
  }
}

function* watchInitSportsInfo() {
  yield takeLatest(initSportsInfoAction, handleInitSportInfo)
}

// 가상게임 init
function* handleInitVirtualInfo(action) {
  try {
    const payload = {
      type: SocketIORequestMessageType.VIRTUAL_INIT,
      payload: {
        // eslint-disable-next-line no-useless-escape
        host: window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1'),
      },
    }

    const res = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (res.status) {
      yield put(setVirtualInitInfo(res.data.virtualSportList))
    }
  } catch (error) {
    // console.log();
  }
}

function* watchInitVirtualInfo() {
  yield takeLatest(initVirtualInfoAction, handleInitVirtualInfo)
}

// 유저 로그 생성용

function* handleCreateUserLog(action) {
  try {
    const payload = {
      type: SocketIORequestMessageType.CLIENT_USER_LOG,
      payload: action.payload,
    }

    yield call(emitPromise, socket, SocketIOEventName.CREATE, payload)
  } catch (error) {
    // console.log();
  }
}

function* watchCreateUserLog() {
  yield takeLatest(createUserLogAction, handleCreateUserLog)
}

export default function* userInfoSaga() {
  yield all([
    watchFetchMemberInfo(),
    watchInitHomeInfo(),
    watchInitPopupInfo(),
    watchInitSportsInfo(),
    watchInitVirtualInfo(),
    watchCreateUserLog(),
  ])
}
