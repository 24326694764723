import React, { useState } from 'react'
import { FaSearchPlus, FaStar } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import 'reactjs-crontab/dist/index.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import { setSelectedBonusFolder } from '../../../redux/sportsBettingSlice'
import { setSearchKeywordParam, setSelectedSportId, setSortType } from '../../../redux/sportsDomesticInfoSlice'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'

const MobileSportsDomesticHeader = () => {
  const dispatch = useDispatch()
  const [showAddSportsType, setShowAddSportsType] = useState(false)

  const [searchKeyword, setSearchKeyword] = useState('')

  const onClickSearchKeywordHandler = () => {
    dispatch(setSearchKeywordParam(searchKeyword))
  }

  const onClickSelectedSportHandler = sportId => {
    dispatch(setSelectedSportId(sportId))
  }

  const onClickSportSortTypeHandler = sortType => {
    dispatch(setSortType(sortType))
  }

  const onClickSportBonusFolderHandler = selectedBonusFolder => {
    dispatch(setSelectedBonusFolder(selectedBonusFolder))
  }

  const { selectedSportId, domesticCategory, domesticParams, bonusFolderInfo, selectedBonusFolder } = useSelector(
    state => {
      const { sportsBetting, sportsDomesticInfo } = state

      const sportsBettingInfo = sportsBetting.sportsBettingInfos[sportsBetting.activeSportsMarketType]

      return {
        selectedSportId: sportsDomesticInfo.selectedSportId,
        domesticCategory: sportsDomesticInfo.domesticCategory,
        domesticParams: sportsDomesticInfo.domesticParams,
        bonusFolderInfo: sportsBettingInfo?.bonusFolderInfo || [],
        selectedBonusFolder: sportsBetting.selectedBonusFolder,
      }
    },
  )

  return (
    <HeaderWrap>
      <SportsGameEventsWrap className="sidebar-container">
        {domesticCategory?.map((item, idx) => (
          <SportsGameEvent
            key={`${item.sportId}-${idx + 1}`}
            $icon={item.sportId}
            onClick={() => {
              onClickSelectedSportHandler(item.sportId)
            }}
            active={item.sportId === selectedSportId}
          >
            <SportsGameEventCount active={item.sportId === selectedSportId}>{item.sportCount}</SportsGameEventCount>
          </SportsGameEvent>
        ))}
      </SportsGameEventsWrap>

      <SportMenuFuncBox>
        <SportsGameMultiFolderWrap>
          <SportsGameMultiFolderHeader>
            <SportsGameMultiFolderHeaderIcon />

            <span style={{ color: '#fff' }}>다폴더 보너스 추가 배당</span>
          </SportsGameMultiFolderHeader>
          <SportsGameMultiFolderContent>
            {bonusFolderInfo.map((folder, index) => (
              <SportsGameMultiFolderContentItem
                key={index}
                isActive={folder.bonusFolderNum === selectedBonusFolder?.bonusFolderNum}
                onClick={() => {
                  onClickSportBonusFolderHandler(folder)
                }}
              >
                {/* <SportsGameMultiFolderImg
                  src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                  style={{ width: '25px' }}
                  alt=""
                /> */}
                <span>{folder.bonusFolderNum}폴더 </span>
                <SportsGameMultiFolderContentOdd>{folder.bonusFolderPer}</SportsGameMultiFolderContentOdd>
              </SportsGameMultiFolderContentItem>
            ))}
          </SportsGameMultiFolderContent>
        </SportsGameMultiFolderWrap>

        <div>
          <SportsGameSortingWrap>
            <SportsGameSortingLeftWrap>
              <SportsGameSortingItem
                isActive={domesticParams.sortType === 'POPULAR'}
                onClick={() => onClickSportSortTypeHandler('POPULAR')}
              >
                <span>인기순 정렬</span>
              </SportsGameSortingItem>
              <SportsGameSortingItem
                isActive={domesticParams.sortType !== 'POPULAR'}
                onClick={() => {
                  onClickSportSortTypeHandler('TODAY')
                }}
              >
                <span>시간대 정렬</span>
              </SportsGameSortingItem>
            </SportsGameSortingLeftWrap>
            <div style={{ width: '100%' }}>
              {domesticParams.sortType !== 'POPULAR' && (
                <SportsGameSortingRightWrap>
                  <SportsGameSortingRightItem
                    isActive={domesticParams.sortType === 'TODAY'}
                    onClick={() => onClickSportSortTypeHandler('TODAY')}
                  >
                    24시간내 경기
                  </SportsGameSortingRightItem>
                  <SportsGameSortingRightItem
                    isActive={domesticParams.sortType === 'TOMORROW'}
                    onClick={() => onClickSportSortTypeHandler('TOMORROW')}
                  >
                    내일 경기
                  </SportsGameSortingRightItem>
                </SportsGameSortingRightWrap>
              )}
            </div>
          </SportsGameSortingWrap>

          <SportsGameSearchWrap>
            <span>검색 : </span>
            <input
              type="text"
              name="searchTeamName"
              value={searchKeyword}
              placeholder="국가/리그/팀"
              onChange={e => {
                setSearchKeyword(e.target.value)
              }}
            />
            <FaSearchPlus
              onClick={() => {
                onClickSearchKeywordHandler()
              }}
            />
          </SportsGameSearchWrap>
        </div>
      </SportMenuFuncBox>
    </HeaderWrap>
  )
}

export default MobileSportsDomesticHeader

const HeaderWrap = styled.div`
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-top: 13px;
  box-sizing: border-box;
  position: relative;
  font-family: 'Malgun Gothic', dotum;
`

const SportsGameEventsWrap = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  position: fixed; /* 위치를 고정시킵니다. */
  top: 145px; /* 화면 상단에 고정합니다. */
  left: 0; /* 화면 좌측에 고정합니다. */
  z-index: 999; /* 다른 요소 위에 위치하도록 z-index 설정합니다. */
  background-color: #161719; /* 배경색을 설정하여 고정된 상태에서 명확하게 보이도록 합니다. */
  padding: 10px 0; /* 컨텐츠와의 간격을 설정합니다. */
  &::-webkit-scrollbar {
    height: 0;
  }
`

const SportsGameEvent = styled.div`
  min-width: 60px;
  height: 59px;
  ${props =>
    props.$icon &&
    `
    background: ${
      props.active
        ? `url(${process.env.PUBLIC_URL}/img/sports/sports-menu/${props.$icon}-hover.png)`
        : `url(${process.env.PUBLIC_URL}/img/sports/sports-menu/${props.$icon}.png)`
    } no-repeat center;  
`}
`

const SportsGameEventCount = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: ${props => (props.active ? `${HermesMainText}` : '#fff')};
  font-size: 12px;
  float: right;
  padding: 3px 5px 3px 5px;
  margin: 1px;
`

const SportMenuFuncBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 70px;

  flex-direction: column;
`

// 다폴더 보너스 배당
const SportsGameMultiFolderWrap = styled.div`
  width: 100%;
  border: 2px solid #222733;
  background: rgba(48, 48, 48, 1);
  border-radius: 3px;
  padding: 8px;
  position: relative;
`

const SportsGameMultiFolderHeader = styled.div`
  font-size: 12px !important;
  float: left;
  display: flex;
  align-items: center;
  gap: 5px;
`

const SportsGameMultiFolderHeaderIcon = styled(FaStar)`
  color: ${HermesMainHover2};
`

const SportsGameMultiFolderContent = styled.div`
  width: 100%;
  height: 50px;
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
`

const SportsGameMultiFolderContentItem = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: rgba(29, 29, 29, 1);
  border: solid 1px ${HermesMainBorder};
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;

  border: 1px solidred;

  span {
    margin-left: 10px;
    float: left;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    display: inline-block;
    text-align: left;
    font-size: 14px;
  }
  div {
    color: ${HermesMainText};
    font-weight: 700;
  }

  ${props =>
    props.isActive &&
    `
    border: solid 2px ${HermesMainText};

    span {
     
    }
    div {
      color: rgba(255, 192, 26, 1);
    }
    `}

  img {
    width 20px;
    height: 20px;
  }
`

// 배당
const SportsGameMultiFolderContentOdd = styled.div`
  background: #0c0c0c;
  width: 33px;
  height: 33px;
  color: #ffba00;
  border-radius: 50%;
  float: right;
  font-weight: 400;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

// 정렬
const SportsGameSortingWrap = styled.div`
  width: 100%;
  border-bottom: 2px solid #222733;
  background: rgba(48, 48, 48, 1);
  border-radius: 3px;
  padding: 6px 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`

const SportsGameSortingLeftWrap = styled.div`
  width: 100%;
  display: flex;
`

const SportsGameSortingRightItem = styled.div`
  color: white;
  min-width: max-content;
  height: 30px;
  font-weight: 400;
  border-radius: 18px;
  padding: 3px 6px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 45% !important;
  margin: 0 2.5%;

  border: 1px solid ${HermesMainBorder};

  ${props =>
    props.isActive &&
    `
    border: 1px solid transparent;
    background: ${HermesMainSelect}
    color: #fff !important;
    `}
`

const SportsGameSortingRightWrap = styled.div`
  width: 100%;
  display: flex;
`

const SportsGameSortingItem = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(29, 29, 29, 1);
  border: none;
  border-radius: 5px;
  padding: 0 15px;
  cursor: pointer;

  width: 45% !important;
  margin: 0 2.5%;

  span {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
  }

  ${props =>
    props.isActive &&
    `
    border: solid 1px rgba(255, 192, 26, 1);
    `}
`

// 검색
const SportsGameSearchWrap = styled.div`
  width: 100%;
  background: rgba(48, 48, 48, 1);
  border-radius: 3px;
  padding: 6px 8px;
  position: relative;
  display: flex;
  gap: 5px;
  justify-content: right;
  align-items: center;
  font-weight: 400;
  color: #fff;
  font-size: 14px;

  input {
    background: rgb(29, 29, 29);
    height: 30px;
    border: none;
    color: ${HermesMainText};
    font-weight: 700;
    font-size: 14px;
    padding-left: 5px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 1px ${HermesMainBorder};
    }
  }
  svg {
    width: 25px;
    height: 20px;
    color: ${HermesMainText};
    &:hover {
      cursor: pointer;
    }
  }
`
